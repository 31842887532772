* {
    margin: 0;
    padding: 0;
    border: none;
}

main {
    align-items: none;
    background: none;
    border-radius: none;
    box-shadow: none;
    font-family: 'Inter', sans-serif;
}

header input {
    padding: 15px;
    margin-left: 20px;
    font-size: 12px;
    border-radius: 10px;
    box-shadow: 0px 5px 5px rgba(198, 203, 212, .5);
    width: 200px;
}

.indicador{
    justify-Content: space-between;
    text-Align:"center";
    margin-Right:0
}

.status{
    justify-Content:space-between;
    padding-Left:15;
    padding-Right:20
}

.statusCol{
    margin:0;
    padding:0
}

.icons {
    color: #41898C;
}


.letra {
    color: #57607A;
    font-size: 16px;
    margin-left: -10px;
}

.text-right {
    margin-top: -64px;
}

.i {
    margin-top: 32px !important;
}

b {
    display: block;
}

.client::placeholder {
    color: rgba(87, 96, 122, 0.5);
}

.place::placeholder {
    color: rgba(87, 96, 122, 0.5);
}

.date::placeholder {
    color: rgb(87, 96, 122)
}

strong {
    color: #57607A;
}

.borde-1 {
    margin-right: 0;
    margin-top: -10px;
    margin-bottom: 0px;
    margin-left: 0;
    border-top: 10px solid #41898C;
    border-radius: 0px 0px 10px 10px;
}

.p2 {
    margin-left: 65px !important;
}

.svg {
    margin: -13px 0px 0px 61px;
    height: 120px;
    width: 120px;
    transform: rotate(312deg);
    border: 10px solid white;
}

.chart1 {
    margin-bottom: -100px;
}

.recent {
    color: #41898C
}

.google-visualization-table-seq {
    text-align: center !important;
}

.google-visualization-table-table td {
    text-align: left;
}

.google-visualization-table-td.google-visualization-table-type-number {
    text-align: center !important;
    white-space: nowrap;
}

.google-visualization-table-th gradient unsorted {
    text-align: left !important;
}

.col {
    width: 100%
}

@media (min-width: 375px) {
    .icons {
        margin-left: 30px
    }

    .client {
        width: 75%;
    }

    .place {
        width: 75%
    }

    .date {
        width: 75%
    }

    .numeros p {
        margin-left: 50px;
    }
    .dropdown-toggle {
        margin-left: 70px;
        margin-top: 10px;
    }

    .tops {
        width: 290px !important;
    }

    .letra {
        margin-left: 0px
    }
    .iPesquisa{
        margin-left: -1.5rem;
    }
}
@media (min-width: 425px) {
    .icons {
        margin-left: 35px;
    }

    .client {
        width: 75%;
    }

    .place {
        width: 75%
    }

    .date {
        width: 75%
    }

    .numeros p {
        margin-left: 49px;
    }

    .tops {
        width: 500px !important;
    }


    .divTotal {
        margin-left: 1.2rem !important;
    }

    .divOrca {
        margin-left: 1.2rem !important;
    }

    .divLinha {
        margin-left: 1.2rem !important;
    }

    .divNegocios {
        margin-left: 1.2rem !important;
    }

    .divTop {
        margin-left: 1.2rem !important;
    }

    .bdate{
        margin-left: .2rem;
    }

    .iPesquisa{
        margin-left: -1.5rem;
    }
}

@media (min-width: 768px) {
    .numeros p {
        margin-left: 100px;
    }

    .icons {
        margin-left: 35px;
    }

    .client {
        width: 84%;
    }

    .place {
        width: 84%
    }

    .date {
        width: 84%
    }

    .tops {
        width: 600px !important;
    }


    .divTotal {
        margin-left: 1.5rem !important;

    }


    .divOrca {
        margin-left: 1.5rem !important;
    }

    .divLinha {
        margin-left: 1.5rem !important;
    }

    .divNegocios {
        margin-left: 1.5rem !important;
    }

    .divTop {
        margin-left: 1.5rem !important;
    }

    .bdate{
        margin-left: .2rem;
    }
    .iPesquisa{
        margin-left: 20rem;
    }
}

@media (min-width: 992px) {
    .numeros p {
        margin-left: 50px;
    }

    .tops {
        width: 329px !important;
    }


    .divTotal {
        margin-left: -.7rem !important;
    }

    .divOrca {
        margin-left: .5rem !important;
    }

    .divLinha {
        margin-left: -.7rem !important;
    }

    .divNegocios {
        margin-left: .5rem !important;
        width: 372px !important;
    }

    .divTop {
        margin-left: -.7rem !important;
    }

    .bdate{
        margin-left: -.3rem;
    }

    .iPesquisa{
        margin-left: 26.3rem;
    }
}

@media (min-width: 1024px) {

    .numeros p {
        margin-left: 12px;
    }


    .icons {
        margin-left: 120px;
        margin-top: -20px;
    }

    .client {
        width: 130% !important;
        margin-left: 30px;
        margin-top: -50px;
    }

    .place {
        width: 130% !important;
        margin-left: 30px;
        margin-top: -50px;
    }

    .date {
        width: 130% !important;
        margin-left: 30px;
        margin-top: -50px;
    }

    .tops {
        width: 435px !important;
    }


    .divTotal {
        margin-left: -.7rem !important;
        width: 534px !important;
    }

    .divOrca {
        margin-left: .5rem !important;
        width: 534px !important;
    }

    .divLinha {
        margin-left: -.7rem !important;
        width: 534px !important;
    }

    .divNegocios {
        margin-left: .5rem !important;
        width: 534px !important;
    }

    .divTop {
        margin-left: -.7rem !important;
        width: 534px !important;
    }

    .bdate{
        margin-left: -.3rem;
    }

    .iPesquisa{
        margin-left: 28rem;
    }
}

@media (min-width: 1240px) {
    .icons {
        margin-left: 50px;
        margin-right: -90px;
    }

    .client {
        margin-left: 5px;
    }

    .place {
        margin-left: 5px;
    }

    .date {
        margin-left: 5px;
    }


    .tops {
        width: 500px !important;
    }


    .divTotal {
        margin-left: 0.2rem !important;
        width: 534px !important;
    }

    .divOrca {
        margin-left: -.5rem !important;
        width: 534px !important;
    }

    .divLinha {
        margin-left: 0.2rem !important;
        width: 534px !important;
    }

    .divNegocios {
        margin-left: -.5rem !important;
        width: 534px !important;
    }

    .divTop {
        margin-left: .2rem !important;
        width: 534px !important;
    }

    .bdate{
        margin-left: -.6rem;
    }

    .iPesquisa{
        margin-left: 40.5rem;
    }

}

@media (min-width: 1366px) {


    .divOrca {
        margin-left: -1.4rem !important;
    }

    .divNegocios {
        margin-left: -1.4rem !important;
    }

    .divTop {
        margin-left: 1.5rem !important;
    }

    .bdate{
        margin-left: -.6rem;
    }

    .iPesquisa{
        margin-left: 50.6rem;
    }
}

@media (min-width: 1366px) {


    .divOrca {
        margin-left: -1.4rem !important;
    }

    .divNegocios {
        margin-left: -1.4rem !important;
    }

    .divTop {
        margin-left: 1.5rem !important;
    }

    .bdate{
        margin-left: -.6rem;
    }
}

@media (min-width: 1440px) {


    .numeros p {
        margin-left: 47px;
    }

    .icons {
        margin-left: 50px;
        margin-right: -70px;
    }

    .client {
        margin-left: 4px;
        width: 80% !important;
    }

    .place {
        margin-left: 4px;
        width: 80% !important;
    }

    .date {
        margin-left: 4px;
        width: 80% !important;
    }

    .div {
        margin-left: -50px;
    }

    .tops {
        width: 552px !important;
    }

    .letra {
        margin-left: 0px
    }


    .divOrca {
        margin-left: -1.4rem !important;
    }

    .divNegocios {
        margin-left: -1.4rem !important;
        width: 601px !important;
    }

    .divTop {
        margin-left: 1.5rem !important;
    }

    .bdate{
        margin-left: -.6rem;
    }

    .iPesquisa{
        margin-left: 55rem;
    }
}

@media (min-width: 1600px) {

    .numeros p {
        margin-left: 47px;
    }

    .icons {
        margin-left: 50px;
        margin-right: -70px;
    }

    .client {
        margin-left: 4px;
        width: 80% !important;
    }

    .place {
        margin-left: 4px;
        width: 80% !important;
    }

    .date {
        margin-left: 4px;
        width: 80% !important;
    }

    .div {
        margin-left: -50px;
    }

    .tops {
        width: 600px !important;

    }

    .letra {
        margin-left: 0px
    }


    .divOrca {
        margin-left: -1.4rem !important;
    }

    .divNegocios {
        margin-left: -1.4rem !important;
    }

    .divTop {
        margin-left: .5rem !important;
    }


    .bdate{
        margin-left: -.6rem;
    }

    .iPesquisa{
        margin-left: 68rem;
    }
}
@media (min-width: 1839px){
    .iPesquisa{
        margin-left: 80.1rem;
    }
}
@media (min-width: 1880px) {

    .divOrca {
        margin-left: -1.4rem !important;
    }

    .divNegocios {
        margin-left: -1.4rem !important;
    }

    .divTop {
        margin-left: .3rem !important;
    }

    .bdate{
        margin-left: -.6rem;
    }
}

.numeros{
    width: 100%;
    font-size: 26px;
    color: #57607A;
    font-weight: bold;
    margin-top: 8px;
    justify-content: end;
}

.img {
    width: 50px;
    padding: 5px;
    border-radius: 10px;
}

.iPendentes {
    background: rgba(30, 48, 85, 0.1);
    color: #57607A;
}
.iEnviadas {
    background: rgba(48, 139, 203, 0.1);
    color: #308BCB;
}
.iFechadas {
    background: rgba(78, 170, 99, 0.1);
    color: #4EAA63;
}
.iRejeitadas {
    background: rgba(214, 54, 104, 0.1);
    color: #D63668;
}
.iRecebidas {
    background: rgba(123, 52, 204, 0.1);
    color: #7B34CC;
}
.iSolicitadas {
    background: rgba(243, 130, 44, 0.1);
    color: #F3822C;
}


.borderPendentes {
    border: 1px solid #1E3055;
}
.borderEnviadas {
    border: 1px solid #308BCB;
}
.borderFechadas {
    border: 1px solid #4EAA63;
}
.borderRejeitadas {
    border: 1px solid #D63668;
}
.borderRecebidas {
    border: 1px solid #7B34CC;
}
.borderSolicitadas {
    border: 1px solid #F3822C;
}

.b1 {
    color: #57607A;
}

.b2 {
    color: #308BCB;
}

.b3 {
    color: #4EAA63;
}

.b4 {
    color: #D63668;
}

.b5 {
    color: #7B34CC;
}

.b6 {
    color: #F3822C;
}


.borde1 {
    margin-right: 0;
    margin-top: -10px;
    margin-bottom: 0px;
    margin-left: 0;
    border-top: 10px solid #1E3055;
    border-radius: 0px 0px 10px 10px;
}


.borde2 {
    margin-right: 0;
    margin-top: -10px;
    margin-bottom: 0px;
    margin-left: 0;
    border-top: 10px solid #308BCB;
    border-radius: 0px 0px 10px 10px;
}

.bord2 {
    border: 1px solid #308BCB;
}

.borde2 {
    margin-right: 0;
    margin-top: -10px;
    margin-bottom: 0px;
    margin-left: 0;
    border-top: 10px solid #308BCB;
    border-radius: 0px 0px 10px 10px;
}

.bord3 {
    border: 1px solid #4EAA63;
}

.borde3 {
    margin-right: 0;
    margin-top: -10px;
    margin-bottom: 0px;
    margin-left: 0;
    border-top: 10px solid #4EAA63;
    border-radius: 0px 0px 10px 10px;
}

.bord4 {
    border: 1px solid #D63668;
}

.borde4 {
    margin-right: 0;
    margin-top: -10px;
    margin-bottom: 0px;
    margin-left: 0;
    border-top: 10px solid #D63668;
    border-radius: 0px 0px 10px 10px;
}

.bord5 {
    border: 1px solid #7B34CC;
}

.borde5 {
    margin-right: 0;
    margin-top: -10px;
    margin-bottom: 0px;
    margin-left: 0;
    border-top: 10px solid #7B34CC;
    border-radius: 0px 0px 10px 10px;
}

.bord6 {
    border: 1px solid #F3822C;
}

.borde6 {
    margin-right: 0;
    margin-top: -10px;
    margin-bottom: 0px;
    margin-left: 0;
    border-top: 10px solid #F3822C;
    border-radius: 0px 0px 10px 10px;
}

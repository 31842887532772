.bootstrap-switch {
    display: inline-block;
    direction: ltr;
    cursor: pointer;
    border-radius: 30px;
    border: 0;
    position: relative;
    text-align: left;
    overflow: hidden;
    margin-bottom: 0;
    line-height: 8px;
    width: 34px !important;
    height: 26px;
    outline: none;
    z-index: 0;
    margin-right: 1px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    -webkit-transition: border-color ease-in-out 0.15s,
        box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.bootstrap-switch .bootstrap-switch-container {
    display: inline-flex;
    top: 0;
    height: 26px;
    border-radius: 4px;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    width: 90px !important;
}

.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off,
.bootstrap-switch .bootstrap-switch-label {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block !important;
    height: 100%;
    color: #fff;
    padding:0;
    font-size: 11px;
    text-indent: 0px;
    line-height: 15px;
    -webkit-transition: 0.25s ease-out;
    transition: 0.25s ease-out;
}

.bootstrap-switch .bootstrap-switch-handle-on i,
.bootstrap-switch .bootstrap-switch-handle-off i,
.bootstrap-switch .bootstrap-switch-label i {
    font-size: 12px;
    line-height: 14px;
}

.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off {
    text-align: center;
    z-index: 1;
    float: left;
    width: 50% !important;
    background-color: #66615b;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-primary {
    color: #fff;
    background: #1B6A9F;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-info,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-info {
    color: #fff;
    background: #68b3c8;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-success,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-success {
    color: #fff;
    background: #7ac29a;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-warning,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-warning {
    background: #f3bb45;
    color: #fff;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-danger,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-danger {
    color: #fff;
    background: #eb5e28;
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default {
    color: #fff;
    background: #cfcfca;
}

.bootstrap-switch .bootstrap-switch-label {
    text-align: center;
    z-index: 100;
    color: #333333;
    background: #ffffff;
    width: 10px !important;
    height: 22px;
    margin: 2px -11px;
    border-radius: 12px;
    position: relative;
    float: left;
    padding: 0;
    background-color: #ffffff;
    box-shadow: 0 1px 1px #ffffff inset, 0 1px 1px rgba(0, 0, 0, 0.25);
}

.bootstrap-switch .bootstrap-switch-handle-on {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
}

.bootstrap-switch input[type='radio'],
.bootstrap-switch input[type='checkbox'] {
    position: absolute !important;
    top: 0;
    left: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    z-index: -1;
}

.bootstrap-switch input[type='radio'].form-control,
.bootstrap-switch input[type='checkbox'].form-control {
    height: auto;
}

.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-label {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
}

.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-label {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
}

.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-label {
    padding: 6px 16px;
    font-size: 18px;
    line-height: 1.33;
}

.bootstrap-switch.bootstrap-switch-disabled,
.bootstrap-switch.bootstrap-switch-readonly,
.bootstrap-switch.bootstrap-switch-indeterminate {
    cursor: default !important;
}

.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-label {
    opacity: 0.5;
    filter: alpha(opacity=50);
    cursor: default !important;
}

.bootstrap-switch.bootstrap-switch-animate .bootstrap-switch-container {
    -webkit-transition: margin-left 0.5s;
    transition: margin-left 0.5s;
}

.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-on {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
}

.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-off {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-container {
    margin-left: -12px !important;
}

.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-container {
    margin-left: -32px !important;
}

.bootstrap-switch.bootstrap-switch-on:hover .bootstrap-switch-label {
    width: 10px !important;
    /* margin: 2px -22px; */
}

.bootstrap-switch.bootstrap-switch-off:hover .bootstrap-switch-label {
    width: 10px !important;
    /* margin: 2px -15px -13px -11px; */
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-handle-off {
    background-color: #66615b;
}

.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-handle-on {
    background-color: #cfcfca;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-danger {
    background-color: #7ac29a;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-warning {
    background-color: #7ac29a !important;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-success {
    background-color: #7ac29a;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-primary {
    background-color: #1B6A9F;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-info {
    background-color: #68b3c8;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-default {
    background-color: #66615b;
}

.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-danger,
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-primary,
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-info,
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-warning,
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-success {
    background-color: #cfcfca;
}

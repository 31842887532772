.iconRascunho {
    background-color: #a7b1da!important;
    width: 0.5rem;
    height: 0.5rem;
    display: inline-block;
    border-radius: 50%;
    -webkit-transform: translateY(-1px);
    transform: translateY(-1px);
    margin-right: .375rem;
    vertical-align: middle;
  }
  .iconEnviado{
      background-color: #6b2c5f!important;
      width: 0.5rem;
      height: 0.5rem;
      display: inline-block;
      border-radius: 50%;
      -webkit-transform: translateY(-1px);
      transform: translateY(-1px);
      margin-right: .375rem;
      vertical-align: middle;
    }

    .iconRealizada{
        background-color:  #3073CB!important;
        width: 0.5rem;
        height: 0.5rem;
        display: inline-block;
        border-radius: 50%;
        -webkit-transform: translateY(-1px);
        transform: translateY(-1px);
        margin-right: .375rem;
        vertical-align: middle;
    }

    .iconCancelado{
        background-color: #E92E09!important;
        width: 0.5rem;
        height: 0.5rem;
        display: inline-block;
        border-radius: 50%;
        -webkit-transform: translateY(-1px);
        transform: translateY(-1px);
        margin-right: .375rem;
        vertical-align: middle;
    }

    .iconAprovado{
        background-color: #4ec34c!important;
        width: 0.5rem;
        height: 0.5rem;
        display: inline-block;
        border-radius: 50%;
        -webkit-transform: translateY(-1px);
        transform: translateY(-1px);
        margin-right: .375rem;
        vertical-align: middle;
    }


    .iconAberto{
        background-color: #96f094a7!important;
        width: 0.5rem;
        height: 0.5rem;
        display: inline-block;
        border-radius: 50%;
        -webkit-transform: translateY(-1px);
        transform: translateY(-1px);
        margin-right: .375rem;
        vertical-align: middle;
    }

    .iconSolicitado{
        background-color: #3ca13b!important;
        width: 0.5rem;
        height: 0.5rem;
        display: inline-block;
        border-radius: 50%;
        -webkit-transform: translateY(-1px);
        transform: translateY(-1px);
        margin-right: .375rem;
        vertical-align: middle;
    }

    .iconConfirmado{
        background-color: #087706!important;
        width: 0.5rem;
        height: 0.5rem;
        display: inline-block;
        border-radius: 50%;
        -webkit-transform: translateY(-1px);
        transform: translateY(-1px);
        margin-right: .375rem;
        vertical-align: middle;
    }

.pPage {
    font-size: 20px;
    color: #1B6A9F;
    font-weight: 700;
}

.pGaleria {
    font-size: 16px;
    color: rgba(87, 96, 122, 0.5);
}

.pResumo {
    color: #1B6A9F;
    font-size: 16px;
}

.img {
    width: 100%;
    height: 100%;
    border-right: 1px solid#ABAFBC;
    border-radius: 10px 0px 0px 10px;
}

.borda {
    border: 1px solid#ABAFBC;
    border-radius: 10px 10px 10px 10px;
    width: 100%;
    background-color: #fff;
}

.inputPes {
    position: absolute;
    top: -5px;
    left: 50px;
    height: 44px;
    width: 200px;
    border-radius: 10px !important;
    box-shadow: 0px 5px 5px rgb(198 203 212 / 50%) !important;
    font-family: 'Inter', sans-serif !important;
    color: #57607A !important;
    border: 0 none !important;
    outline: 0 !important;
    padding-left: 10px;
}

.nomeProduto {
    color: #57607A;
    font-size: 16px;
    font-weight: 700;
}

.nomeComponente {
    color: #57607A;
    font-size: 12px;
    margin: 28px 0px 0px 53px;
}

.iconeCopia {
    font-size: 24px;
    color: #57607A !important;
}

.iconeCopia2 {
    font-size: 24px;
    color: #57607A !important;
}

.iconeCopia3 {
    font-size: 24px;
    color: #57607A !important;
}

.iconeLixeira {
    font-size: 20px;
    color: #57607A !important;
}

.inputs {
    margin: 55px 0px 0px;
}

.inputItem {
    padding: 0px 0px 0px 2px;
    width: 90px;
    height: 30px;
    font-size: 10px;
    border: 1px solid #57607A;
    border-radius: 10px;
    text-align: center;
    background: transparent;

}

.pQuantidade {
    font-size: 12px;
    text-align: left;
    color: #57607A;
}
.titulo{
    text-align: left;
    font-size: 12px;
    color: #57607A;
}

.pCor {
    color: #57607A;
    margin: 5px 0px -3px -1px;
}

.buttonMenos {
    margin: 0px -40px 0px 17px;
    background: transparent;
    font-size: 24px;
    color: #57607A;
}

.buttonMais {
    font-size: 14px;
    background: transparent;
    color: #57607A;
    margin: 0px 0px 0px -35px;
}

.divCor {
    margin: 60px 0px 0px 44px;
}

.select {
    width: 240px;
    height: 50px;
    border: 1px solid #57607A;
    border-radius: 10px;
    background: transparent;
    color: #57607A
}

.pDesc {
    font-size: 14px;
    color: #57607A;
}

.desc {
    margin: 167px 0px 10px -432px;
}

.valor {
    background-color: rgba(171, 175, 188, 0.3);
    color: rgba(87, 96, 122, 0.7);
    border: 1px solid #ABAFBC;
    width: 168px;
    height: 50px;
    font-size: 16px;
    padding: 12px 0px 0px 12px;
    margin: 0px 0px 0px -79px;
}

.total {
    font-size: 12px;
    color: #57607A;
    margin: -18px -46px 0px 73px;
}

.bottomImport {
    color: #fff;
    background-color: #57607A;
    width: 151px;
    height: 44px;
    border-radius: 10px !important;
    box-shadow: 0px 5px 5px rgb(198 203 212 / 50%) !important;
    padding: 10px;
    margin-left: -60px
}

.inputP {
    width: 200px;
    height: 44px;
    margin-left: 380px;
}

.pOrçamento {
    font-size: 28px;
    font-weight: 700;
    color: #57607A;
}

.pColor {
    color: #57607A;
}

.bEnviar {
    width: 100%;
    height: 54px;
    color: white;
    background-color: #4EAA79;
    display: flex;
    font-size: 20px;
    justify-content: center;
    padding: 10px 0px;
}
.bRejeitar {
    color:  #de0707;
    background-color: white;
    width: 100%;
    height: 40px;
    display: flex;
    font-size: 13px;
    justify-content: center;
    padding: 10px 0px;
}

.bEditar {
    width: 100%;
    height: 40px;
    color: white;
    background-color: #308BCB;
    display: flex;
    font-size: 13px;
    justify-content: center;
    padding: 10px 0px;
}

.bAguardar {
    width: 100%;
    height: 48px;
    color: gray;
    background-color: #cde2d7;
    display: flex;
    font-size: 16px;
    justify-content: center;
    padding: 10px 0px;
}

.bFechar {
    width: 100%;
    height: 48px;
    color: white;
    background-color: #4eaa7960;
    display: flex;
    font-size: 16px;
    justify-content: center;
    padding: 10px 0px;
}

.box {
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #ABAFBC;
    padding: 40px;
    padding-left: 45px !important;
    padding-right: 45px !important;
}

.linha {
    height: 100px;
    border: 1px solid #ABAFBC;
    transform: rotate(90deg);
}

.titulo{
    border-bottom: 1px solid #bdc0c7;
    margin-top: 20px;
    padding-bottom: 10px;
    align-items: baseline;
}
.title {
    font-size: 20px;
    font-family: sans-serif;
    margin-left: -.8rem;
    margin: 0;
    padding: 0;
    color: #57607A;
}
.title strong{
    color: #048fc1db;
}

.pCusto {
    font-size: 24px;
    font-weight: 700;
    color: #4EAA79;
}

.pTotal {
    font-size: 24px;
    font-weight: 700;
    color: #57607A;
}

.bConti {
    background-color: transparent;
    color: #3073CB;
    font-size: 13px;
    float: right;
}

.iconeEs {
    font-size: 24px;
}

.pProduto {
    font-size: 16px;
    font-weight: 700;
    color: #57607A;
    margin: 20px 0px 0px 1px;
}

.bImpre {
    color: #3073CB;
    font-size: 16px;
    display: flex;
    background-color: transparent;
    margin: -24px 0px 0px 599px;
}

.iconeIm {
    font-size: 24px;
}

.pGold {
    font-size: 16px;
    color: #57607A;
}

.pQtd {
    font-size: 16px;
    color: #57607A;
}

.pNum {
    font-size: 16px;
    color: #57607A;
    font-weight: 700;
    margin-left: 110px;
}

.pNum1 {
    font-size: 16px;
    color: #57607A;
    font-weight: 700;
    margin-left: 136px;
}

.pNum2 {
    font-size: 16px;
    color: #57607A;
    font-weight: 700;
    margin-left: 134px;
}

.pNum3 {
    font-size: 16px;
    color: #57607A;
    font-weight: 700;
    margin-left: 126px;
}

.linhaP {
    border: 1px solid #ABAFBC;
    transform: rotate(90deg);
    margin: -312px 0px 0px -346px;
}

.divI {
    margin-bottom: -335px;
}

.bordaButton {
    background-color: #1B6A9F;
    height: 14px;
    border-radius: 0px 0px 10px 10px;
    margin-top: 3px;
}

.bExcluir {
    font-size: 16px;
    color: #D63657;
    background-color: transparent;
    display: flex;
    float: right;
}

.iLixeira {
    font-size: 24px;
    color: #D63657 !important;
}

.input-bloqueado {
    background-color: #F5F7FB !important;
    color: #A6ABBA;
}
#linha{
    border-bottom: 1px solid #A6ABBA;
}
#container{
    height: 550px;
    overflow-x: auto;
}

@media (min-width: 1336px){
    .input {
        padding: 0px 0px 0px 12px;
        width: 130px;
        height: 50px;
        border: 1px solid #57607A;
        border-radius: 10px;
        text-align: center;
        background: transparent;
    }
    .bEnviar {
        width: 100%;
        height: 40px;
        color: white;
        background-color: #4EAA79;
        display: flex;
        font-size: 12px;
        justify-content: center;
        padding: 10px 0px;
    }
    .pOrçamento {
        font-size: 22px;
        font-weight: 700;
        color: #57607A;
    }
    .pGold {
        font-size: 12px;
        color: #57607A;
    }
    .pQtd {
        font-size: 12px;
        color: #57607A;
    }
}
@media (min-width: 1440px){
    .input {
        padding: 0px 0px 0px 12px;
        width: 150px;
        height: 50px;
        border: 1px solid #57607A;
        border-radius: 10px;
        text-align: center;
        background: transparent;

    }
}

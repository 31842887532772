#item{
    margin-left: 30px;
    margin-bottom: 15px;
    margin-top: -1px;
}
#item .icon-h{
    margin-right: 15px;
}
.pesquisa{
    justify-content: end;
}
.pesquisa .tipo{
    display: flex;
    align-self: center;
}
.pesquisa p{
    margin: 15px 10px 0 0;
}
.lupa{
    display: flex;
    margin-right: 30px;
}
.novoItem{
    border-bottom:1px solid #DFE3E9;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    padding-bottom: 8px;
}
.novoItem p{
    font-size: 20px;
    font-weight: 700;
    color: #57607A;
    margin: 15px 0 15px 0;
}
.catalogo{
    font-size: 20px;
}
.strong{
    font-weight: bolder;
    font-size: 24px;
    color: #6b2c5f !important;
}
.pItens{
    text-align: center;
}
.produto{
    margin: 15px;
    padding: 5px;
    border: 1px solid #cccfd4;
    border-radius: 10px;
}
.produto img{
   margin: -2px;
   width: 111px;
   height: 85px;
}
.desc{
    font-weight: 700;
    color: #57607A;
}
.acao{
    display: flex;
    flex-direction: column;
}

.iconITENS {
    color: #1B6A9F;
    font-size: 20px;
    padding-top: 10px !important;
}

.inputITEM {
    padding: 10px;
    border: 1px solid #57607A;
    border-radius: 10px;
    height: 51px;
}

.itenCriar {
    padding: 10px;
    height: 44px;
    width: 123px;
    background-color: #3073CB;
}

.itenCriar p {
    font-size: 14px;
    margin-top: 2px !important;
    font-weight: 700;
    color: #fff;
}

.itenCriar i {
    font-size: 16px;
    color: #fff;
}

.itenImportar {
    padding: 10px;
    height: 44px;
    width: 182px;
    background-color: #ABAFBC;
}

.itenImportar p {
    font-size: 14px;
    margin-top: 2px !important;
    font-weight: 700;
    color: #fff;
}

.itenImportar i {
    font-size: 16px;
    color: #fff;
}

.selectL {
    padding: 10px;
    border: 1px solid #57607A;
    border-radius: 10px;
    height: 51px;
    width: 159px;
    color: #ABAFBC;
}

.itensLista {
    font-size: 16px;
    font-weight: 700;
    color: #1B6A9F;
}

.linhaLista {
    border: 1px solid #DFE3E9;
    width: 100%;
    height: 1px;
    margin-top: 50px;
    margin-left: 400px;
}

.pNumero {
    color: #1B6A9F;
    font-size: 16px;
}

.linhaItens {
    border: 1px solid #DFE3E9;
    height: 100%;
    width: 1px;
}

.pDescricao {
    font-size: 16px;
    font-weight: 700;
    color: #57607A;
}

.pPerfil {
    font-size: 16px;
    color: #57607A;
}

.recordTotal{
    justify-content: end;
    margin: 15px 10px 0px 0px!important;
}

.inputCheck {
    margin-top: 27px !important;
}

.switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off {
    text-align: center;
    z-index: 1;
    float: left;
    width: 100% !important;
    background-color: #66615b;
}

.bootstrap-switch {
    display: inline-block;
    direction: ltr;
    cursor: pointer;
    border-radius: 30px;
    border: 0;
    position: relative;
    text-align: left;
    overflow: hidden;
    margin-bottom: 5px;
    line-height: 0px;
    width: 10px !important;
    height: 14px !important;
    outline: none;
    z-index: 0;
    margin-right: 1px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-container {
    margin-left: -37px !important;
    margin-right: 10px !important;
}

.bootstrap-switch .bootstrap-switch-container {
    display: inline-flex;
    top: 0;
    height: 26px;
    border-radius: 4px;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    width: 100px !important;
}

.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off,
.bootstrap-switch .bootstrap-switch-label {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block !important;
    height: 100%;
    color: #fff;
    padding: 6px 10px;
    font-size: 11px;
    text-indent: -5px;
    line-height: 15px;
    -webkit-transition: 0.25s ease-out;
    transition: 0.25s ease-out;
}

.bootstrap-switch .bootstrap-switch-label {
    text-align: center;
    z-index: 100;
    color: #333333;
    background: #ffffff;
    width: 10px !important;
    height: 10px !important;
    margin: 2px -11px;
    border-radius: 12px;
    position: relative;
    float: left;
    padding: 0;
    background-color: #ffffff;
    box-shadow: 0 1px 1px #ffffff inset, 0 1px 1px rgb(0 0 0 / 25%);
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-container {
    margin-left: -7px !important;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-warning {
    background-color: #1B6A9F !important;
}

.IconeLixeira {
    font-size: 24px;
    margin-top: 19px;
}

.iconesOpcoes button {
    color: none !important;
}


.linhaDiv {
    padding: 30px;
    border-radius: 20px;
    border: 1px solid #ABAFBC
}

.imagemIten2 {
    width: 88px;
    height: 66px;
}
@media (min-width: 375px) {}

@media (min-width: 390px) {
    .containerItens card-body {
        width: 100%;
    }

    .iconesOpcoes {
        margin-top: 1.5rem;
        margin-left: -5rem
    }

    .inputITEM {
        padding: 10px;
        border: 1px solid #57607A;
        border-radius: 10px;
        height: 51px;
        width: 134px;
        margin-left: 26px;
    }

    .buttonItens {
        margin-left: -10px !important;
    }

    .itensLista {
        font-size: 14px;
        font-weight: 700;
        color: #1B6A9F;
    }

    .iconITENS {
        margin-left: 25px;
    }

    .card {
        width: 100%;
    }

    .itenImportar {
        margin-left: 4.2rem;
    }

    .linhaLista {
        border: 1px solid #DFE3E9;
        width: 108%;
        height: 1px;
        margin-top: 90px;
        margin-left: -24px;
    }

    .selectL {
        padding: 10px;
        border: 1px solid #57607A;
        border-radius: 10px;
        height: 51px;
        width: 159px;
        color: #ABAFBC;
        margin-left: 97px;
    }

    .linhaDiv {
        width: 335px;
        height: 113px;
        margin-left: 19px;
    }

    /* .imgItens {
        width: 111px;
        height: 83px;
        margin: -16px 0px 0px -15px;
    } */

    .imgIten2 {
        width: 111px;
        height: 83px;
        margin: -16px 0px 0px -15px;
    }

    .pNumero {
        font-size: 12px;
        width: 80px;
        margin: -14px 0px 0px 15px;
    }

    .pDescricao {
        font-size: 12px;
        width: 150px;
        margin: 4px 0px 0px -3.7rem;
    }

    .linhaItens1 {
        border: 1px solid #DFE3E9;
        height: 50px;
        margin-left: -.8rem;
        margin-top: 21px;
        transform: rotate(90deg);
    }

    .linhaItens2 {
        display: none;
    }

    .linhaItens3 {
        display: none;
    }

    .pPerfil {
        font-size: 12px;
        margin: 50px 0px 0px -6.2rem;
    }

    .pPerfil1 {
        color: #57607A;
        font-size: 12px;
        margin: -.9rem 0px 0px 9rem;
        width: 100px;
        text-align: end;
    }

    .inputSwitch {
        margin: 5px 0px 0px -35px;
    }

    .bottom1 {
        margin-left: 5rem !important;
    }

    .checkPesquisa {
        margin-left: -1rem !important;
    }

    .checkPesquisa1 {
        margin-left: -2rem !important;
    }

    .checkPesquisa2 {
        margin-left: -1rem !important;
    }

    .checkPesquisa3 {
        margin-left: -1rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pTipologia {
        margin-left: -1rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pVidros {
        margin-left: -1rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pCores {
        margin-left: -1rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pOrca {
        margin-left: -2rem !important;
        margin-bottom: 1rem;
    }

    .fOrca {
        margin-bottom: 1rem;
    }

    .checkFiltro {
        margin-left: 1rem !important;
    }

    .boxButton2 {
        margin-left: 8rem !important;
    }
}

@media (min-width: 425px) {
    .containerItens card-body {
        width: 100%;
    }

    .inputITEM {
        padding: 10px;
        border: 1px solid #57607A;
        border-radius: 10px;
        height: 51px;
        width: 134px;
    }

    .buttonItens {
        margin-left: -10px !important;
    }

    .itensLista {
        font-size: 14px;
        font-weight: 700;
        color: #1B6A9F;
    }

    .iconITENS {
        margin-left: 25px;
    }

    .card {
        width: 100%;
    }

    .itenImportar {
        margin-left: 74px;
    }

    .linhaLista {
        border: 1px solid #DFE3E9;
        width: 108%;
        height: 1px;
        margin-top: 90px;
        margin-left: -29px;
    }

    .selectL {
        padding: 10px;
        border: 1px solid #57607A;
        border-radius: 10px;
        height: 51px;
        width: 159px;
        color: #ABAFBC;
        margin-left: 129px;
    }

    .linhaDiv {
        width: 335px;
        height: 113px;
    }

    /* .imgItens {
        width: 111px;
        height: 83px;
        margin: -16px 0px 0px -15px;
    } */

    .imgIten2 {
        width: 111px;
        height: 83px;
        margin: -16px 0px 0px -15px;
    }

    .pNumero {
        font-size: 12px;
        margin: -14px 0px 0px 15px;
        width: 120px;
    }

    .pDescricao {
        font-size: 12px;
        margin: 4px 2px 0px -4rem;
    }

    .linhaItens1 {
        border: 1px solid #DFE3E9;
        height: 50px;
        margin: 1.4rem 0px 10px -6rem;
        transform: rotate(90deg);
    }

    .linhaItens2 {
        display: none;
    }

    .linhaItens3 {
        display: none;
    }

    .pPerfil {
        font-size: 12px;
        margin: 51px 4rem 0px -6.7rem;
    }

    .pPerfil1 {
        color: #57607A;
        font-size: 12px;
        margin: -1rem 0px 0px 3.3rem;
        text-align: end;
    }

    .inputSwitch {
        margin: 5px 0px 0px -35px;
    }

    .IconeLixeira {
        font-size: 24px;
        margin-top: 42px;
        margin-left: -33px;
    }

    .bottom1 {
        margin-left: 5rem !important;
    }

    .checkPesquisa {
        margin-left: -1rem !important;
    }

    .checkFiltro {
        margin-left: 1rem !important;
    }

    .checkPesquisa1 {
        margin-left: -2rem !important;
    }

    .checkPesquisa2 {
        margin-left: -1rem !important;
    }

    .checkPesquisa3 {
        margin-left: -1rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pTipologia {
        margin-left: -1rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .boxButton2 {
        margin-left: 8rem !important;
    }
}

@media (min-width: 768px) {
    .card {
        width: 91%;
        margin-left: 30px;
    }

    .pItens {
        margin-left: 30px;
    }

    .inputITEM {
        padding: 10px;
        border: 1px solid #57607A;
        border-radius: 10px;
        height: 51px;
        width: 134px;
        margin-left: 35px;
    }

    .buttonItens {
        margin-left: -10px !important;
    }

    .itensLista {
        font-size: 14px;
        font-weight: 700;
        color: #1B6A9F;
    }

    .iconITENS {
        margin-left: 45px;
        padding-top: 14px !important;
    }

    .itenImportar {
        margin-left: 326px;
    }

    .linhaLista {
        border: 1px solid #DFE3E9;
        width: 104%;
        height: 1px;
        margin-top: 90px;
        margin-left: -29px;
    }

    .selectL {
        padding: 10px;
        border: 1px solid #57607A;
        border-radius: 10px;
        height: 51px;
        width: 159px;
        color: #ABAFBC;
        margin-left: 380px;
    }

    .linhaDiv {
        width: 335px;
        height: 113px;
        margin-left: 25px;
    }

    /* .imgItens {
        width: 111px;
        height: 83px;
        margin: -16px 0px 0px -15px;
    } */

    .imgIten2 {
        width: 111px;
        height: 83px;
        margin: -16px 0px 0px -15px;
    }

    .pNumero {
        font-size: 12px;
        margin: -14px 0px 0px 15px;
        width: 150px;
    }

    .pDescricao {
        font-size: 12px;
        margin: .2rem 0rem 0px -11.4rem;
        width: 200px;
    }

    .linhaItens1 {
        border: 1px solid #DFE3E9;
        height: 50px;
        margin: 1rem 0rem 0rem -7.8rem;
        transform: rotate(90deg);
    }

    .linhaItens2 {
        display: none;
    }

    .linhaItens3 {
        display: none;
    }

    .pPerfil {
        font-size: 14px;
        margin: 2.7rem 50% 0px -16.5rem;
    }

    .pPerfil1 {
        color: #57607A;
        font-size: 12px;
        margin: -14px 0px 0px 50px;
    }

    .inputSwitch {
        margin: 5px 0px 0px -35px;
    }

    .IconeLixeira {
        font-size: 24px;
        margin-top: 42px;
        margin-left: -32px;
    }

    .buttonsItens {
        margin-right: 0% !important;
    }

    .bottom1 {
        margin-left: 1rem !important;
    }

    .checkPesquisa {
        margin-left: 2rem !important;
    }

    .check-pesquisa {
        width: 150px !important;
    }

    .check-filtros {
        width: 150px !important;
    }

    .checkFiltro {
        margin-left: -5rem !important;
    }

    .checkPesquisa1 {
        margin-left: 28rem !important;
        margin-top: -4.3rem;
    }

    .checkPesquisa2 {
        margin-left: 29.2rem !important;
    }

    .checkPesquisa3 {
        margin-left: 29.3rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pTipologia {
        margin-left: 29.3rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pVidros {
        margin-left: 29.3rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pCores {
        margin-left: 29.3rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pOrca {
        margin-left: -2rem !important;
        margin-bottom: 1rem;
    }

    .fOrca {
        margin-bottom: 1rem;
        margin-left: -5rem !important;
        margin-right: 4rem !important;
    }


    .boxButton2 {
        margin-left: 2rem !important;
    }

}

@media (min-width: 992px) {
    .selectL {
        padding: 10px;
        border: 1px solid #57607A;
        border-radius: 10px;
        height: 51px;
        width: 159px;
        color: #ABAFBC;
        margin-left: 10px;
    }

    .iconITENS {
        margin-left: 5.5rem;
    }

    .itenCriar {
        padding: 10px;
        height: 44px;
        width: 125px;
        margin-left: -5rem !important;
    }

    .linhaLista {
        border: 1px solid #DFE3E9;
        width: 63%;
        height: 1px;
        margin-top: 50px;
        margin-left: 250px;
    }

    .pNumero {
        font-size: 16px;
        margin: 15px 0px 0px 10px;
        width: 110px;
    }

    .linhaItens1 {
        border: 1px solid #DFE3E9;
        height: 60px;
        margin: -5px 0px 0px .5rem;
        transform: rotate(360deg);
    }

    .pDescricao {
        font-size: 16px;
        margin: 15px 0px 0px -6rem;
        width: 200px;
    }

    .linhaItens2 {
        display: block;
        border: 1px solid #DFE3E9;
        height: 60px;
        margin: -5px 0px 0px .5rem;
        transform: rotate(360deg);
    }

    .pPerfil {
        font-size: 14px;
        margin: 17px 0px 0px 15px;
    }

    .pPerfil1 {
        color: #57607A;
        font-size: 12px;
        margin: -1px -2.1rem 0px 4rem;
        text-align: end;
    }

    .linhaItens3 {
        display: none;
    }

    .inputSwitch {
        margin: 19px 0px 0px 4px;
    }

    .IconeLixeira {
        font-size: 24px;
        margin-top: 12px;
        margin-left: 15px;
    }

    .buttonsItens {
        margin-left: -5px;
    }

    .itenImportar {
        margin-right: 1rem !important;
        margin-left: 26rem;
    }

    .checkPesquisa {
        margin-left: 2rem !important;
    }

    .check-pesquisa {
        width: 150px !important;
    }

    .check-filtros {
        width: 150px !important;
    }

    .checkFiltro {
        margin-left: -3.5rem !important;
    }

    .checkPesquisa1 {
        margin-left: 34.5rem !important;
        margin-top: -4.3rem;
    }

    .checkPesquisa2 {
        margin-left: 35.5rem !important;
    }

    .checkPesquisa3 {
        margin-left: 35.6rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pTipologia {
        margin-left: 35.7rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pVidros {
        margin-left: 35.7rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pCores {
        margin-left: 35.7rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pOrca {
        margin-left: -2rem !important;
        margin-bottom: 1rem;
    }

    .fOrca {
        margin-bottom: 1rem;
        margin-left: -3.5rem !important;
        margin-right: 4rem !important;
    }
}

@media (min-width: 1024px) {
    .buttonsItens {
        margin-left: 11px;
    }

    .linhaDiv {
        width: 335px;
        height: 113px;
        margin-left: 29px;
    }

    .linhaItens1 {
        margin-left: 1rem;
    }

    .pDescricao {
        font-size: 16px;
        margin: 15px 0px 0px -6rem;
    }

    .itenCriar {
        margin-left: 5px;
    }

    .itenImportar {
        margin-left: 435px;
    }

    .iconITENS {
        margin-left: 89px;
        padding-top: 14px !important;
    }

    .divDeLista {
        margin-left: 5px;
    }

    .checkPesquisa {
        margin-left: 2rem !important;
    }

    .check-pesquisa {
        width: 170px !important;
    }

    .check-filtros {
        width: 170px !important;
    }

    .checkFiltro {
        margin-left: -4.2rem !important;
    }

    .checkPesquisa1 {
        margin-left: 35rem !important;
    }

    .checkPesquisa2 {
        margin-left: 36.3rem !important;
    }

    .checkPesquisa3 {
        margin-left: 36.3rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pTipologia {
        margin-left: 36.3rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pVidros {
        margin-left: 36.1rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pCores {
        margin-left: 36.1rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pOrca {
        margin-left: -2rem !important;
        margin-bottom: 1rem;
    }

    .fOrca {
        margin-bottom: 1rem;
        margin-left: -4.2rem !important;
        margin-right: 4rem !important;
    }
}

@media (min-width: 1240px) {
    .itensPesquisa {
        margin-bottom: -64px;
    }

    .buttonsItens {
        margin-left: 166px;
    }

    .linhaDiv {
        width: 335px;
        height: 113px;
        margin-left: 34px;
    }

    .pDescricao {
        font-size: 16px;
        margin: 15px 0rem 0rem -10rem;

    }

    .pPerfil1 {
        color: #57607A;
        font-size: 12px;
        margin: 19px 0rem 0px -5rem;
    }

    .pPerfil {
        margin: 17px 0px 0px 1rem;
        width: 100px;
    }

    .linhaLista {
        border: 1px solid #DFE3E9;
        width: 68%;
        height: 1px;
        margin-top: 50px;
        margin-left: 265px;
    }

    .linhaItens1 {
        margin-left: 2.5rem;
    }

    .linhaItens2 {
        margin-left: -5rem;
    }

    .linhaItens3 {
        border: 1px solid #DFE3E9;
        height: 50px;
        margin: 0rem -1.5rem 0rem 2.2rem;
        display: block;
    }

    .iconesOpcoes {
        margin: 0rem -.5rem 0rem -2.4rem;
    }

    .itenImportar {
        margin-right: 2rem !important;
        margin-left: -5rem;
    }

    .iconITENS {
        margin-left: 2.5rem;
    }

    .itenImportar {
        margin-right: 1rem !important;
        margin-left: 2rem;
    }

    .itenCriar {
        margin-left: -5rem;
    }

    .checkPesquisa {
        margin-left: 1rem !important;
    }

    .check-pesquisa {
        width: 170px !important;
    }

    .check-filtros {
        width: 170px !important;
    }

    .checkFiltro {
        margin-left: .1rem !important;
    }

    .checkPesquisa1 {
        margin-left: 47.5rem !important;
    }

    .checkPesquisa2 {
        margin-left: 48.5rem !important;
    }

    .checkPesquisa3 {
        margin-left: 48.5rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pTipologia {
        margin-left: 48.6rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pVidros {
        margin-left: 48.6rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pCores {
        margin-left: 48.6rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pOrca {
        margin-left: -2rem !important;
        margin-bottom: 1rem;
    }

    .fOrca {
        margin-bottom: 1rem;
        margin-left: .1rem !important;
        margin-right: 3rem !important;
    }
}

@media (min-width: 1280px) {
    .buttonsItens {
        margin-left: 194px;
    }

    .linhaLista {
        border: 1px solid #DFE3E9;
        width: 69%;
        height: 1px;
        margin-top: 50px;
        margin-left: 265px;
    }

    .pDescricao {
        font-size: 16px;
        margin: 16px 0px 0px -260px;
    }

    .pTipologia {
        margin-left: 50.6rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pVidros {
        margin-left: 50.6rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pCores {
        margin-left: 50.6rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pOrca {
        margin-left: -2rem !important;
        margin-bottom: 1rem;
    }

    .fOrca {
        margin-bottom: 1rem;
        margin-left: .6rem !important;
        margin-right: 3rem !important;
    }
}

@media (min-width: 1366px) {
    .linhaLista {
        border: 1px solid #DFE3E9;
        width: 71%;
        height: 1px;
        margin-top: 50px;
        margin-left: 265px;
    }

    .iconITENS {
        margin-left: 3.2rem;
    }

    .itenImportar {
        margin-right: 2rem !important;
        margin-left: 2rem;
    }

    .linhaItens3 {
        margin: 0rem -2rem 0rem 2.4rem;
        display: block;
    }

    .pPerfil {
        margin: 17px 3rem 0px -1rem;
        width: 100px;
    }

    .checkPesquisa {
        margin-left: 1rem !important;
    }

    .check-pesquisa {
        width: 190px !important;
    }

    .check-filtros {
        width: 190px !important;
    }

    .checkFiltro {
        margin-left: 3.8rem !important;
    }

    .checkPesquisa1 {
        margin-left: 56.5rem !important;
    }

    .checkPesquisa2 {
        margin-left: 57.5rem !important;
    }

    .checkPesquisa3 {
        margin-left: 57.5rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pTipologia {
        margin-left: 57.6rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pVidros {
        margin-left: 57.6rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pCores {
        margin-left: 57.6rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pOrca {
        margin-left: -2rem !important;
        margin-bottom: 1rem;
    }

    .fOrca {
        margin-bottom: 1rem;
        margin-left: 3.9rem !important;
        margin-right: 3rem !important;
    }
}

@media (min-width: 1440px) {
    .buttonsItens {
        margin-left: -15px;
    }

    .linhaLista {
        border: 1px solid #DFE3E9;
        width: 72%;
        height: 1px;
        margin-top: 50px;
        margin-left: 273px;
    }

    .linhaDiv {
        width: 335px;
        height: 113px;
        margin-left: 36px;
    }

    .pPerfil1 {
        margin: 19px -1rem 0px -4rem;
    }

    .pDescricao {
        font-size: 16px;
        margin: 15px 0px 0px -18.5rem;
    }

    .IconeLixeira {
        font-size: 24px;
        margin-top: 12px;
        margin-left: 45px;
    }

    .iconITENS {
        margin-left: 45px;
        padding-top: 14px !important;
    }

    .inputITEM {
        padding: 10px;
        border: 1px solid #57607A;
        border-radius: 10px;
        height: 51px;
        width: 134px;
        margin-left: 30px;
    }

    .linhaDiv {
        width: 335px;
        height: 113px;
        margin-left: 35px;
    }

    .itenImportar {
        margin-right: .2rem !important;
        margin-left: 4rem;
    }

    .itenCriar {
        padding: 10px;
        height: 44px;
        width: 125px;
        background-color: #3073CB;
        margin-left: 2%;
        margin-right: -10%;
    }

    .iconesOpcoes {
        margin-left: -2.5rem;
        margin-right: 1rem;
    }

    .btnLixeira {
        margin-left: 1rem !important;
        margin-right: -.5rem !important;
    }

    .checkPesquisa {
        margin-left: -.5rem !important;
    }

    .check-pesquisa {
        width: 190px !important;
    }

    .check-filtros {
        width: 190px !important;
    }

    .checkFiltro {
        margin-left: 6.6rem !important;
    }

    .checkPesquisa1 {
        margin-left: 60.7rem !important;
    }

    .checkPesquisa2 {
        margin-left: 61.7rem !important;
    }

    .checkPesquisa3 {
        margin-left: 65.9rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pTipologia {
        margin-left: 61.9rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pVidros {
        margin-left: 61.9rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pCores {
        margin-left: 61.9rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pOrca {
        margin-left: -2rem !important;
        margin-bottom: 1rem;
    }

    .fOrca {
        margin-bottom: 1rem;
        margin-left: 5.2rem !important;
        margin-right: 3rem !important;
    }
}

@media (min-width: 1600px) {
    .itensPesquisa {
        margin-left: 10px;
    }

    .divDeLista {
        margin-left: 10px;
    }

    .buttonsItens {
        margin-left: 478px;
    }

    .pDescricao {
        font-size: 16px;
        margin: 15px 0px 0px -20rem;
    }

    .pPerfil1 {
        font-size: 12px;
        margin: 19px 0px 0px 1rem;
    }

    .IconeLixeira {
        font-size: 24px;
        margin-top: 12px;
        margin-left: 30px;
    }

    .iconesOpcoes {
        margin-left: -.5rem;
        margin-right: 1rem;
    }

    .itenImportar {
        margin-right: .9rem !important;
        margin-left: 4rem;
    }

    .linhaLista {
        border: 1px solid #DFE3E9;
        width: 74%;
        height: 1px;
        margin-top: 50px;
        margin-left: 273px;
    }

    .linhaItens1 {
        margin-left: 2.5rem;
    }

    .linhaItens2 {
        margin-left: -4rem;
    }

    .pPerfil {
        margin: 17px 3rem 0px 1rem;
        width: 100px;
    }

    .btnLixeira {
        margin-left: 1.5rem !important;
        margin-right: -.1rem !important;
    }

    .linhaItens3 {
        margin: 0rem -3rem 0rem 2.4rem;
        display: block;
    }

    .inputSwitch {
        margin: 19px 1rem 0px .1rem;
    }

    .checkPesquisa {
        margin-left: -.8rem !important;
    }

    .check-pesquisa {
        width: 200px !important;
    }

    .check-filtros {
        width: 200px !important;
    }

    .checkFiltro {
        margin-left: 9.2rem !important;
    }

    .checkPesquisa1 {
        margin-left: 69.7rem !important;
    }

    .checkPesquisa2 {
        margin-left: 70.7rem !important;
    }

    .checkPesquisa3 {
        margin-left: 70.9rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pTipologia {
        margin-left: 70.8rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pVidros {
        margin-left: 70.8rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pCores {
        margin-left: 70.8rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pOrca {
        margin-left: -2rem !important;
        margin-bottom: 1rem;
    }

    .fOrca {
        margin-bottom: 1rem;
        margin-left: 9.4rem !important;
        margin-right: 1rem !important;
    }
}

@media (min-width: 1800px) {
    .buttonsItens {
        margin-left: 630px;
    }

    .linhaLista {
        border: 1px solid #DFE3E9;
        width: 76.5%;
        height: 1px;
        margin-top: 50px;
        margin-left: 275px;
    }

    .linhaItens1 {
        border: 1px solid #DFE3E9;
        height: 60px;
        margin-left: 50px;
        margin-top: -5px;
        transform: rotate(360deg);
    }

    .pDescricao {
        font-size: 16px;
        margin: 15px 0px 0px -25rem;
    }

    .pPerfil1 {
        color: #57607A;
        font-size: 12px;
        margin: 19px 0px 0px 190px;
    }

    .iconITENS {
        margin-left: 4rem;
        padding-top: 14px !important;
    }

    .itenImportar {
        margin-right: 1.4rem !important;
        margin-left: 4rem;
    }

    .checkPesquisa {
        margin-left: -3.6rem !important;
    }

    .check-pesquisa {
        width: 200px !important;
    }

    .check-filtros {
        width: 200px !important;
    }

    .checkFiltro {
        margin-left: 15.6rem !important;
    }

    .checkPesquisa1 {
        margin-left: 81.3rem !important;
    }

    .checkPesquisa3 {
        margin-left: 82.7rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pTipologia {
        margin-left: 82.8rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pVidros {
        margin-left: 82.8rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pCores {
        margin-left: 82.8rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pOrca {
        margin-left: -2rem !important;
        margin-bottom: 1rem;
    }

    .fOrca {
        margin-bottom: 1rem;
        margin-left: 15rem !important;
        margin-right: -1rem !important;
    }

}

@media (min-width: 1880px) {
    .buttonsItens {
        margin-left: 705px;
    }

    .linhaLista {
        border: 1px solid #DFE3E9;
        width: 77%;
        height: 1px;
        margin-top: 50px;
        margin-left: 281px;
    }

    .linhaItens1 {
        border: 1px solid #DFE3E9;
        height: 60px;
        margin-left: 6rem;
        margin-top: -5px;
        transform: rotate(360deg);
    }

    .pDescricao {
        font-size: 16px;
        margin: 15px 0px 0px -25rem;
    }

    .pPerfil1 {
        color: #57607A;
        font-size: 14px;
        margin: 19px 0px 0px 5rem;
    }

    .IconeLixeira {
        font-size: 24px;
        margin-top: 12px;
        margin-left: 40px;
    }

    .itenCriar {
        padding: 10px;
        height: 44px;
        width: 125px;
        background-color: #3073CB;
        margin-left: -6%;
        margin-right: -10%;
    }

    .linhaDiv {
        width: 335px;
        height: 113px;
        margin-left: 42px;
    }

    .checkPesquisa {
        margin-left: -3.6rem !important;
    }

    .check-pesquisa {
        width: 200px !important;
    }

    .check-filtros {
        width: 200px !important;
    }

    .checkFiltro {
        margin-left: 16.7rem !important;
    }

    .checkPesquisa1 {
        margin-left: 86.3rem !important;
    }

    .checkPesquisa3 {
        margin-left: 87.5rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pTipologia {
        margin-left: 87.4rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pVidros {
        margin-left: 87.4rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pCores {
        margin-left: 87.4rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    .pOrca {
        margin-left: -2rem !important;
        margin-bottom: 1rem;
    }

    .fOrca {
        margin-bottom: 1rem;
        margin-left: 17.5rem !important;
        margin-right: -2rem !important;
    }
}

@media (min-width: 1920px) {
    .checkPesquisa3 {
        margin-left: 14.5rem !important;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }
}


.btn-cor{
    background-color: #6b2c5f!important;
}

.btn-segundary {
    background-color: #7ac29a !important;
}

.form-control
{
    height: 50px !important;
}

.table{
    border: 1px solid #ced3e4;
    text-align: center;
}
thead{
    background-color: #ced3e4;
}
.pInicial {
    font-size: 20px;
    font-weight: 700;
    color: #1B6A9F;
    margin-top: 1rem;
}

.codItem, .codERP{

    height: 50px !important;
    /*  */

}

.descItem{
    border: 1px solid #ABAFBC;
    height: 50px;
}

.divItenss {
    color: #57607A;
}

.switchItem {
    margin-left: 10px !important;
}

.TipoItem {
    margin: -58px 0px 8px 160px;
    color: #57607A;
}

.descP1 {
    margin: 10px 0px 0px 395px;
}

.cameraImg {
    color: #CBCED6 !important;
}

.linhaCamera {
    border: 1px solid #DFE3E9;
    width: 40%;
    margin-left: 30%;
    margin-top: -10px;
    margin-bottom: 10px;
}

.enviarImgT {
    border: 1px solid #DFE3E9;
    border-radius: 10px;
    padding: 6px 6px 6px 6px;
    color: #CBCED6;
}
.verticalLinha {
    height: 80%;
    border: 1px solid #ABAFBC;
}

.inputEmbalagem {
    width: 135px;
    height: 45px;
    color: #ABAFBC;
    border: 1px solid #ABAFBC;
    padding-left: 10px;
    border-radius: 10px;
    margin-right: 20px;
    margin-top: -.8rem;
    margin-bottom: 1rem;
}

.inputsMedida2 {
    margin-top: -.1rem;
    margin-bottom: 1rem;
}

.inputsMedida2 p {
    margin-bottom: .2rem;
}

.SelectUnd {
    border: 1px solid #ABAFBC;
    width: 187px;
    padding: 10px;
    border-radius: 10px;
    height: 47px;
    color: #57607A;
}

.SelectCor {
    border: 1px solid #57607A;
    width: 400px;
    padding: 10px;
    border-radius: 10px;
    height: 47px;
    color: #ABAFBC;
    margin-bottom: 1.5rem;
}

.inputCor2 {
    width: 125px;
    height: 50px;
    color: #ABAFBC;
    border: 1px solid #ABAFBC;
    padding-left: 10px;
    border-radius: 15px;
    margin-right: 20px;
}

.btnnLixeira {
    margin-top: 2.5rem;
}

.verticalLinha {
    width: 48.5%;
    margin-left: -21rem;
    margin-top: 26rem !important;
    margin-right: -21rem;
    border: 1px solid #DFE3E9;
    transform: rotate(90deg);
}

.coresEbottons {
    margin-top: 1rem;
}

.btnnLixeira {
    color: #57607A;
    font-size: 20px;
    background-color: transparent;
    margin-top: .3rem;
    margin-left: 10.5rem;
}

.linhadeCor {
    border: 1px solid #DFE3E9;
    margin-top: -.7rem;
    width: 190%;
}

.bdeSalvar {
    padding: 10px;
    height: 40px;
    width: 200px;
    margin-top: .7rem;
    font-weight: 600;
    color: #fff;
    background-color: #1B6A9F;
    margin-right: .5rem;
}

.bdeCancelar {
    padding: 10px;
    height: 40px;
    width: 200px;
    margin-top: .7rem;
    font-weight: 600;
    color: #fff;
    background-color: #57607A;
    margin-left: 22.1rem;
    margin-right: .5rem;
}

@media (min-width: 390px) {

    .descP1 {
        margin: 10px 0px 0px 90px;
    }

    .verticalLinha {
        display: none;
    }

    .inputsMedida {
        margin-left: 1.5rem;
    }
    .SelectUnd {
        width: 180px;
    }

    .SelectCor {
        margin-left: 1.5rem;
        width: 300px;
    }
    .linhadeCor {
        width: 340px;
        margin-left: 1.5rem;
    }
    .btnnLixeira {
        margin-left: -.9rem;
    }
    .bdeSalvar {
        margin-left: 1.5rem;
        width: 150px;
    }
    .bdeCancelar {
        margin-left: 1.8rem;
        width: 150px;
    }
}

@media (min-width: 425px) {

    .TipoItem {
        margin-left: 12.3rem;
    }

    .descP1 {
        margin: 10px 0px 0px 110px;
    }

    .verticalLinha {
        display: none;
    }

    .inputsMedida {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }
    .SelectUnd {
        width: 180px;
    }

    .SelectCor {
        margin-left: 1.5rem;
        width: 320px;
    }
    .linhadeCor {
        width: 370px;
        margin-left: 1.5rem;
    }
    .btnnLixeira {
        margin-left: -.7rem;
    }
    .bdeSalvar {
        margin-left: 1.5rem;
        width: 150px;
    }
    .bdeCancelar {
        margin-left: 4rem;
        width: 150px;
    }
}

@media (min-width: 768px) {

    .TipoItem{
        margin-left: 27.2rem;
    }
    .descP1{
        margin-left: 21.5rem;
    }

    .inputsMedida{
        margin-left: 1.5rem;
        margin-right: 16.6rem;
    }

    .SelectCor {
        margin-left: 1.5rem;
        width: 550px;
    }
    .btnnLixeira{
        margin-left: -.9rem;
    }
    .linhadeCor{
        width: 600px;
    }
    .bdeCancelar{
        margin-left: 18.5rem;
    }
}

@media (min-width: 992px) {

    .TipoItem {
        margin-left: 34.7rem;
    }
    .descP1 {
        margin-left: 29rem;
    }

    .inputsMedida {
        margin-left: 1.5rem;
        margin-right: 23.5rem;
    }

    .linhadeCor {
        width: 715px;
    }
    .btnnLixeira {
        margin-left: -1rem;
    }
    .bdeCancelar {
        margin-left: 25.5rem;
    }
}

@media (min-width: 1024px) {

    .TipoItem {
        margin-left: 36.6rem;
    }
    .descP1 {
        margin-left: 31rem;
    }

    .inputsMedida {
        margin-right: 25.5rem;
    }

    .linhadeCor {
        width: 745px;
    }
    .bdeCancelar {
        margin-left: 27.5rem;
    }
}

@media (min-width: 1240px) {

    .TipoItem {
        margin-left: 16.6rem;
    }

    .descP1 {
        margin-left: 10.9rem;
    }
    .verticalLinha {
        width: 62.5%;
        margin-left: -17.5rem;
        margin-top: 20.5rem !important;
        margin-right: -41rem;
        transform: rotate(90deg);
        display: block;
    }

    .SelectCor {
        margin-left: 23.5rem;
        width: 350px;
    }
    .linhadeCor {
        width: 450px;
        margin-left: 23.5rem;
    }
    .bdeSalvar {
        margin-left: 23.5rem;
        width: 150px;
    }
    .bdeCancelar {
        margin-left: 9rem;
    }
}

@media (min-width: 1366px) {
    .verticalLinha {
        width: 56.5%;
        margin-left: -14rem;
        margin-top: 21.5rem !important;
        margin-right: -38rem;
        transform: rotate(90deg);
        display: block;
    }
 }

@media (min-width: 1440px) {
    .verticalLinha {
        width: 50.5%;
        margin-left: -15.5rem;
        margin-top: 20.5rem !important;
        margin-right: -39rem;
        transform: rotate(90deg);
        display: block;
    }
    .TipoItem {
        margin-left: 22.2rem;
    }

    .descP1 {
        margin-left: 16.8rem;
    }

    .inputEmbalagem {
        width: 160px;
    }
    .inputsMedida {
        margin-right: 8.5rem;
        margin-left: 23.5rem;
    }

    .inputsMedida {
        margin-right: 7.5rem;
        margin-left: 23.5rem;
    }
    .inputEmbalagem {
        width: 200px;
    }
    .SelectUnd {
        width: 200px;
    }
    .linhadeCor {
        width: 530px;
        margin-left: 23.5rem;
    }
    .bdeCancelar {
        margin-left: 14rem;
    }
}

@media (min-width: 1600px) {

    .TipoItem {
        margin-left: 25.2rem;
    }

    .descP1 {
        margin-left: 19.8rem;
    }

    .verticalLinha {
        width: 46.5%;
        margin-left: -15rem;
        margin-top: 21.5rem !important;
        margin-right: -39rem;
    }
    .inputEmbalagem {
        width: 220px;
    }
    .SelectUnd {
        width: 220px;
    }
    .inputsMedida {
        margin-right: 9rem;
        margin-left: 23.5rem;
    }

    .SelectCor {
        width: 450px;
    }
    .linhadeCor {
        width: 595px;
        margin-left: 23.5rem;
    }
    .bdeCancelar {
        margin-left: 18rem;
    }

}

@media (min-width: 1800px) {

    .descP1 {
        margin-left: 22.9rem;
    }

    .verticalLinha {
        width: 41.5%;
        margin-left: -13rem;
        margin-top: 22rem !important;
        margin-right: -37rem;
    }
    .inputsMedida {
        margin-right: 1rem;
    }

    .SelectCor {
        width: 530px;
    }
    .linhadeCor {
        width: 675px;
        margin-left: 23.5rem;
    }
    .bdeCancelar {
        margin-left: 23rem;
    }
}

@media (min-width: 1880px) {
    .verticalLinha {
        width: 40%;
        margin-left: -13rem;
        margin-top: 21rem !important;
        margin-right: -37rem;
    }
    .SelectCor {
        width: 560px;
    }
    .linhadeCor {
        width: 715px;
        margin-left: 23.5rem;
    }
    .bdeCancelar {
        margin-left: 25.5rem;
    }
}

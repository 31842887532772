.egShop .nav a:hover {
    color: #6b2c5f !important;
}

.egShop .nav a:hover i,
.egShop .off-canvas-sidebar .nav a:hover i {
    color: #6b2c5f !important;
}

.egShop #minimizeSidebar.btn,
.egShop .navbar .navbar-nav>a.btn,
.egShop .btn-primary {
    background-color: #6b2c5f !important;
}

.egShop #minimizeSidebar.btn:hover,
.egShop .navbar .navbar-nav>a.btn:hover,
.egShop .btn-primary:hover,
.egShop #minimizeSidebar.btn:focus,
.egShop .navbar .navbar-nav>a.btn:focus,
.egShop .btn-primary:focus {
    background-color: #8A567F !important;
}

.egShop .imgUsuario {
    border: 2px solid #8A567F !important;
}

.egShop .btn-primary {
    background-color: #6b2c5f !important;
}

.egShop .btn-segundary {
    background-color: #7ac29a !important;
}

.egShop #minimizeSidebar.btn,
.egShop .navbar .navbar-nav>a.btn,
.egShop .btn-primary {
    background-color: #6b2c5f !important;
}

.egShop .btn-neutral:hover,
.egShop .btn-neutral:focus,
.egShop .btn-neutral:active {
    background-color: #6b2c5f !important;
    color: #FFF !important;
}

.egShop .pagination .page-item.active>.page-link:focus,
.egShop .pagination .page-item.active>.page-link:hover {
    background-color: #8A567F !important;
    border-color: #8A567F !important;
}

.egShop .card {
    border-bottom: #6b2c5f solid 14px;
}

.egShop .dashboardRevenda .card{
    border-bottom: none !important;
}

.egShop table.table thead {
    background: none !important;
    color: #6b2c5f !important;
    border: 1px solid #dee2e6 !important;
}

.egShop table.table thead tr th {
    padding: 10px 10px !important;
}

.egShop table.table thead tr th span {
    margin-left: 10px;
}

.egShop .react-datepicker__close-icon::after {
    background-color: #6b2c5f !important;
}

@media (min-width: 425px) {
    .egShop .rowBarras {
        margin: 0px;
    }
    .egShop .colBarras {
        padding-top: 10px;
    }
}

.qtdItem{
   display: flex;
   font-size: 12px;
   max-height: 30px;
}

 #qtdItem{
    max-height: 30px;
 }

 .menos{
    font-size: 18px;
    background-color: transparent;
    color: #57607A;
}
.mais{
    font-size: 12px;
    padding: 3px;
    background-color: transparent;
    color: #57607A;
 }




.item {
    margin: 10px 0px;
    padding: 8px 0px;
    border: 1px solid #bdbfc6;
    border-left: 3px solid #1B6A9F;
    border-radius: 10px;
    align-items: center;
}

.data {
    font-size: 16px;
    color: #1B6A9F;
    font-weight: 700;
}
.subtitle {
    font-size: 12px;
    font-weight: 400;
    color: #515768;
}

.custo {
    color: #1B6A9F;
    font-size: 14px;
    font-weight: 400;
}

.container-custom-input-file {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.custom-input-file {
    display: none;
}

.custom-input-label {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 4px;
    background-color: #6b2c5f;
    color: #fff;
    cursor: pointer;
}

.custom-input-label:hover {
    background-color: #6b2c5f;
}
.form-control {
	height: auto !important;
}
textarea.form-control {
	max-width: auto !important;
	max-height: auto !important;
}

.dropdown-menu {
	border-radius: 0;
	border: 1px solid rgba(0, 0, 0, 0.15);
	padding: 0 !important;
	top: 38px !important;
}
.dropdown-menu .dropdown-item {
	border-radius: 0 !important;
}

.container-fluid {
	padding-right: 0 !important;
}

input,
select {
	box-shadow: none !important;
}

.tim-row {
	margin-bottom: 20px;
}

.tim-white-buttons {
	background-color: #777777;
}

.typography-line {
	padding-left: 25%;
	margin-bottom: 35px;
	position: relative;
	display: block;
	width: 100%;
}

.typography-line span {
	bottom: 10px;
	color: #c0c1c2;
	display: block;
	font-weight: 400;
	font-size: 13px;
	line-height: 13px;
	left: 0;
	position: absolute;
	width: 260px;
	text-transform: none;
}

.tim-row {
	padding-top: 60px;
}

.tim-row h3 {
	margin-top: 0;
}

.offline-doc .page-header {
	display: flex;
	align-items: center;
}

.offline-doc .footer {
	position: absolute;
	width: 100%;
	background: transparent;
	bottom: 0;
	color: #fff;
	z-index: 1;
}

#map {
	position: relative;
	width: 100%;
	height: 100vh;
}

.demo-iconshtml {
	font-size: 62.5%;
}

.demo-icons body {
	font-size: 1.6rem;
	font-family: sans-serif;
	color: #333333;
	background: white;
}

.demo-icons a {
	color: #608cee;
	text-decoration: none;
}

.demo-icons header {
	text-align: center;
	padding: 100px 0 0;
}

.demo-icons header h1 {
	font-size: 2.8rem;
}

.demo-icons header p {
	font-size: 1.4rem;
	margin-top: 1em;
}

.demo-icons header a:hover {
	text-decoration: underline;
}

.demo-icons .nc-icon {
	font-size: 34px;
}

.demo-icons section h2 {
	border-bottom: 1px solid #e2e2e2;
	padding: 0 0 1em 0.2em;
	margin-bottom: 1em;
}

.demo-icons ul {
	padding-left: 0;
}

.demo-icons ul::after {
	clear: both;
	content: "";
	display: table;
}

.demo-icons ul li {
	width: 20%;
	float: left;
	padding: 16px 0;
	text-align: center;
	border-radius: 0.25em;
	-webkit-transition: background 0.2s;
	-moz-transition: background 0.2s;
	transition: background 0.2s;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	overflow: hidden;
}

.demo-icons ul li:hover {
	background: #f4f4f4;
}

.demo-icons ul p,
.demo-icons ul em,
.demo-icons ul input {
	display: inline-block;
	font-size: 1rem;
	color: #999999;
	-webkit-user-select: auto;
	-moz-user-select: auto;
	-ms-user-select: auto;
	user-select: auto;
	white-space: nowrap;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	cursor: pointer;
}

.demo-icons ul p {
	padding: 20px 0 0;
	font-size: 12px;
	margin: 0;
}

.demo-icons ul p::selection,
.demo-icons ul em::selection {
	background: #608cee;
	color: #efefef;
}

.demo-icons ul em {
	font-size: 12px;
}

.demo-icons ul em::before {
	content: "[";
}

.demo-icons ul em::after {
	content: "]";
}

.demo-icons ul input {
	text-align: center;
	background: transparent;
	border: none;
	box-shadow: none;
	outline: none;
	display: none;
}

/*Alterações template --------------------------------------------------------------------*/

/*Links padrões*/
a {
	color: #09456b;
}

a:hover {
	color: #3e6a88;
}

.cursor-move {
	cursor: move;
}

div.disabled {
	background-color: #e3e3e3 !important;
}

.main-panel {
	background-color: rgba(186, 186, 186, 0.1);
}

/*Fontes*/
.font-10 {
	font-size: 10px !important;
}

/*Sidebar --------*/
.sidebar.sidebar-sync:after {
	background: none;
}

.sidebar-sync {
	background: none;
	background-color: #fff !important;
	color: #fff;
}

.sidebar.sidebar-sync[data-active-color="primary"] .nav li.active > a i,
.sidebar[data-active-color="primary"] .nav li.active > a {
	color: #fff;
}

/*Botão minimizar*/
#minimizeSidebar.btn,
.navbar .navbar-nav > a.btn,
.btn-primary {
	background-color: #09456b;
}

#minimizeSidebar.btn:hover,
.navbar .navbar-nav > a.btn:hover,
.btn-primary:hover,
#minimizeSidebar.btn:focus,
.navbar .navbar-nav > a.btn:focus,
.btn-primary:focus {
	background-color: #3e6a88 !important;
}

/*Navbar*/
.logo,
.navbar.navbar-sync {
	background-color: #fff !important;
	border: none;
}

.logo .logo-normal {
	color: #66615b !important;
}

.sidebar-mini .logo {
	min-height: 62px;
}

.sidebar-mini a.logo-mini {
	text-align: center;
	margin: 0 !important;
	width: 100% !important;
}

.sidebar-mini a.logo-mini .logo-image-small {
	text-align: center;
}

.sidebar-mini .logo .logo-normal {
	display: none !important;
}

.sidebar .logo .logo-normal {
	padding: 7px 0 7px !important;
}

.sidebar .user a {
	color: #fff;
}

/*.sidebar .user:after { background-color: rgba(50, 31, 79, 0.5); }*/

.sidebar[data-active-color="primary"] .nav li.active > a,
.sidebar[data-active-color="primary"] .nav li.active > a i,
.sidebar[data-active-color="primary"]
	.nav
	li.active
	> a[data-toggle="collapse"],
.sidebar[data-active-color="primary"]
	.nav
	li.active
	> a[data-toggle="collapse"]
	i,
.sidebar[data-active-color="primary"]
	.nav
	li.active
	> a[data-toggle="collapse"]
	~ div
	> ul
	> li.active
	.sidebar-mini-icon,
.sidebar[data-active-color="primary"]
	.nav
	li.active
	> a[data-toggle="collapse"]
	~ div
	> ul
	> li.active
	> a,
.off-canvas-sidebar[data-active-color="primary"] .nav li.active > a,
.off-canvas-sidebar[data-active-color="primary"] .nav li.active > a i,
.off-canvas-sidebar[data-active-color="primary"]
	.nav
	li.active
	> a[data-toggle="collapse"],
.off-canvas-sidebar[data-active-color="primary"]
	.nav
	li.active
	> a[data-toggle="collapse"]
	i,
.off-canvas-sidebar[data-active-color="primary"]
	.nav
	li.active
	> a[data-toggle="collapse"]
	~ div
	> ul
	> li.active
	.sidebar-mini-icon,
.off-canvas-sidebar[data-active-color="primary"]
	.nav
	li.active
	> a[data-toggle="collapse"]
	~ div
	> ul
	> li.active
	> a {
	color: #fff !important;
}

.sidebar .sidebar-wrapper li.active > a:not([data-toggle="collapse"]):before,
.sidebar .sidebar-wrapper li.active > a:not([data-toggle="collapse"]):after {
	border-right: 17px solid #f9f8f7 !important;
}

.sidebar .nav li > a {
	color: #fff;
}

.navbar .bootstrap-select .btn {
	margin: 3px 1px !important;
}

.card-description {
	color: black;
}

/*Elementos CSS inline*/
.cursor-default {
	cursor: default !important;
}

.border-collapse {
	border-collapse: collapse !important;
}

.no-border {
	border: none !important;
}

.no-border-th-t-b th {
	border-top: none;
	border-bottom: none;
}

.no-background {
	background: none !important;
}

.display-none {
	display: none;
}

/*Forms*/
textarea.form-control {
	padding: 2px 8px !important;
}

.boder-success {
	border: solid 1px #28a745 !important;
}

input.boder-success {
	border-left: none !important;
}

.input-group-text.boder-success {
	border-right: none !important;
}

/*Botões padrões*/
.btn-primary {
	background-color: #09456b !important;
	color: #fff !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.btn-primary.active:focus,
.btn-primary.active:hover,
.show > .btn-primary.dropdown-toggle,
.show > .btn-primary.dropdown-toggle:focus,
.show > .btn-primary.dropdown-toggle:hover {
	background-color: #3e6a88 !important;
}

.btn-neutral {
	background-color: transparent !important;
}

.btn-neutral:hover,
.btn-neutral:focus,
.btn-neutral:active {
	background-color: #09456b !important;
	color: #fff !important;
}

.btn-danger {
	color: #fff !important;
}

.btn-red {
	background-color: #cc2127;
	color: #fff !important;
}

.btn-red-over {
	background-color: transparent !important;
	color: #212529;
	font-size: 20px !important;
}

.btn-red-over:hover {
	background-color: #cc2127 !important;
	color: #fff !important;
}

.btn-purple {
	color: #fff;
	background-color: #4f2478;
}

.btn-purple:hover {
	color: #fff !important;
	background-color: #47216b !important;
}

.btn-purple.btn-link {
	color: #fff;
	background-color: #4f2478;
}

.btn-purple.btn-link:hover {
	color: #fff;
	background-color: #47216b;
}

.btn-success.btn-link:hover,
.btn-success {
	color: #fff !important;
}

.btn-success.btn-link {
	color: #6bd098 !important;
}

.btn-red:hover {
	background-color: #af1d23 !important;
}

.btn-neutral:active:focus {
	color: #09456b !important;
}

.btn-primary:focus,
.btn-primary:active {
	background-color: #3e6a88 !important;
}

.text-danger {
	color: #dc3545 !important;
}

.btn-red.btn-link {
	color: #cc2127 !important;
	background-color: transparent !important;
}

.btn-red.btn-link:hover {
	color: #ffffff !important;
	background-color: #cc2127 !important;
}

/*Páginas-----------------------------------------------------------*/
/*Botão selecionar revenda*/
.btn-revenda .filter-option-inner-inner {
	font-size: 15px;
	font-weight: 400 !important;
}

.btn-revenda
	.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(
		.input-group-btn
	) {
	width: auto;
}

.btn-revenda i {
	color: #403d39;
	font-size: 18px;
	position: relative;
	top: 2px;
}

.btn-revenda button:hover,
.btn-revenda button:focus,
.btn-revenda button:active,
.btn-revenda button:active:focus,
.btn-revenda .show > .btn.dropdown-toggle:focus,
.btn-revenda .show > .btn.dropdown-toggle:hover,
.btn-revenda .show > .btn.dropdown-toggle {
	background: rgba(232, 234, 237, 0.078) !important;
	color: #403d39 !important;
}

.btn-revenda .bootstrap-select .dropdown-item.active {
	background-color: #e8e8e9;
	color: #3c4043;
}

.btn-revenda .bootstrap-select .dropdown-item:hover {
	background-color: #f7f8f8;
	color: #3c4043 !important;
}

.btn-revenda .bootstrap-select .dropdown-menu li.disabled a {
	color: #bfbfbf !important;
}

/*Orçamentos - Novo Orçamento*/
#novo-orcamento .nav-tabs-navigation {
	margin-bottom: 0;
}

/*Tab Wizard - Orçamentos - Novo Orçamento - Levantamento*/
.wizard .nav-pills .nav-item {
	width: 14%;
	text-align: center;
}

.wizard .nav-pills .nav-item .nav-link {
	padding: 10px 0;
}

.wizard .nav-pills .nav-item:first-child .nav-link,
.nav-pills .nav-item:last-child .nav-link,
#levantamento .nav-pills .nav-item:last-child .nav-link {
	border-radius: 0 0 0 0 !important;
	-webkit-border-radius: 0 0 0 0 !important;
	-moz-border-radius: 0 0 0 0 !important;
	-ms-border-radius: 0 0 0 0 !important;
	-o-border-radius: 0 0 0 0 !important;
}

.wizard .nav-pills-primary .nav-item.show .nav-link,
.wizard .nav-pills-primary .nav-link.active {
	background-color: #09456b !important;
}

.wizard .nav-pills-primary .nav-item .nav-link {
	border: none;
	border-bottom: 4px solid #b2c4cf;
	color: #b2c4cf;
	font-weight: normal;
	height: 100%;
}

.wizard .nav-pills-primary .nav-item .nav-link i {
	background-color: rgba(87, 96, 122, 10%);
	font-size: 24px;
	padding: 10px;
	border-radius: 10px;
}

.wizard .nav-pills-primary .nav-item .nav-link.active {
	border-bottom: 4px solid #41898c;
	background: none !important;
	color: #41898c;
	font-weight: normal;
}

.wizard .nav-pills-primary .nav-item .nav-link.active i {
	background-color: rgba(65, 137, 140, 15%);
}

/*- Novo Orçamento - Levantamento - Tab Itens*/
.wizard #tab-itens .container-fluid {
	padding: 0;
}

.wizard #tab-itens .table > thead > tr > th {
	font-size: 12px;
}

/*Parametrização*/
.bt-modal {
	position: relative;
	top: 18px;
}

.tooltip,
.arrow,
.tooltip-inner {
	width: auto !important;
	max-width: none;
	max-height: none;
	min-height: none;
	min-width: none;
}

.tooltip.show {
	opacity: 100;
}

/*Item Especial*/
#modal-levantamento-item-especial .wizard .nav-pills .nav-item {
	width: 25%;
}

#modal-custo-venda-item .wizard .nav-pills .nav-item {
	width: 25%;
}

#custo-venda.wizard .nav-pills .nav-item {
	width: 25%;
}

/*Notificações*/
.card-collapse .card .card-header a:hover {
	color: #09456b;
	text-decoration: none;
}

#accordion .card.card-plain.selected {
	background-color: #f7f8f8;
}

#accordion .card.card-plain .card-header {
	padding-left: 10px;
	padding-right: 10px;
}

/*Fim de Páginas -------------------------------------------------------*/

/*Icones*/
.icon-1x {
	font-size: 110%;
}

.icon-2x {
	font-size: 120%;
}

.icon-3x {
	font-size: 130%;
}

.icon-4x {
	font-size: 140%;
}

.icon-5x {
	font-size: 150%;
}

.icon-6x {
	font-size: 160%;
}

.icon-7x {
	font-size: 170%;
}

.icon-8x {
	font-size: 180%;
}

.icon-9x {
	font-size: 190%;
}

.icon-10x {
	font-size: 200%;
}

.icon-30x {
	font-size: 2rem;
}
.icon-red {
	color: #cc2127 !important;
}

.icon-primary {
	color: #92284c !important;
}

.icon-success {
	color: #28a745 !important;
}

.icon-image-dashboard {
	height: 59px;
	width: 47px;
	vertical-align: top;
}

/*blockquote*/
.blockquote.blockquote-neutro {
	border: 1px solid #f1eae0;
}

/*rowReorder*/
th.rowReorder {
	width: 57px;
	text-align: center;
}

table.dataTable thead > tr > th.rowReorder.sorting_asc,
table.dataTable thead > tr > th.rowReorder.sorting_desc {
	padding: 0;
}

/*Card Login*/
.card.card-login {
	background-color: rgba(255, 255, 255, 90%);
}

.card-login .input-group-text {
	background-color: #fff;
}

/*Modal*/
.modal-body {
	padding: 10px 30px;
}

/*DataTable --------------------------------------------------------------*/
/*Borda*/
.table-bordered thead {
	border-bottom: 1px solid #dee2e6 !important;
}

.table-bordered {
	font-size: 16px !important;
}

.container-fluid {
	padding-left: 0;
	padding-right: 0;
}

/*Busca DataTable*/
.form-control.form-control-sm {
	border: 1px solid #dddddd;
	padding: 15px;
	box-shadow: 0px 10px 15px -10px rgb(198 203 212);
	border-radius: 10px;
}

/*Filtros DataTable*/
.dataTables_filter select.form-control {
	float: right;
	width: 200px;
	margin: 0 0px 0 10px;
}

/*Paginaçãp DataTable*/
.pagination .page-item.active > .page-link {
	background-color: #0f456a;
	border-color: #0f456a;
}

.pagination .page-item.active > .page-link:focus,
.pagination .page-item.active > .page-link:hover {
	background-color: #3e6a88;
	border-color: #3e6a88;
}

.licencas {
	color: #9a9a9a;
	font-weight: bold;
	float: right;
}

p.licencas {
	margin: 16px 0 0 0;
}

@media screen and (max-width: 411px) {
	.licencas {
		display: contents;
	}
}

/*Rateio Checkbox*/
.combo-check {
	margin-top: 10px;
	list-style: none;
	text-align: right;
}

.combo-check .category {
	padding: 6px;
}

/*Tabela*/

th.check {
	min-width: auto;
}

th {
	min-width: 140px;
}

.border-table {
	border: solid 1px #ddd;
}

.tabela-sem-barra {
	overflow: auto;
}

/*Tablet ---------------------------------------------------------------------------------------------*/
@media screen and (max-width: 1024px) {
	/*Orçamento - Novo Orçamento - Tabs levantamento*/
	#levantamento .nav-pills-primary .nav-item .nav-link {
		font-size: 9px;
	}
}

@media screen and (max-width: 768px) {
	/*Filtros Tabela*/
	.dataTables_filter select.form-control,
	div.dataTables_wrapper div.dataTables_filter input {
		width: 165px;
	}

	.dataTables_wrapper .row div.col-sm-12.col-md-6:first-child {
		max-width: 23%;
	}

	.dataTables_wrapper .row div.col-sm-12.col-md-6:last-child {
		max-width: 77%;
	}

	.dataTables_wrapper .row div.col-sm-12.col-md-6 .dataTables_filter {
		width: 529px;
	}

	.col-sm-12,
	.col-md-6 {
		padding-right: 15px;
		padding-left: 15px;
	}

	.modal-xl {
		max-width: 745px !important;
	}

	.modal-body {
		padding: 10px;
	}

	.wizard .nav-pills-primary .nav-item .nav-link {
		font-size: 9px;
	}
}

/*Mobile ---------------------------------------------------------------------------------------------*/
@media screen and (max-width: 767px) {
	.navbar .navbar-toggler {
		width: auto;
		height: auto;
	}

	.navbar a:not(.btn):not(.dropdown-item) {
		color: rgba(0, 0, 0, 0.5);
	}

	.navbar-collapse {
		flex-basis: 100%;
		flex-grow: 1;
		position: absolute;
		align-items: center;
		z-index: 2;
		right: 0;
		top: 50px;
		width: 100%;
		padding: 10px 20px 20px 20px;
		background-color: #09456b;
	}

	.navbar-collapse a {
		color: #fff !important;
	}

	.main-panel .content {
		padding-left: 4.5px;
		padding-right: 4.5px;
	}

	.display-none-mobile {
		display: none;
	}

	.toolbar {
		display: none;
	}

	.dataTables_filter select.form-control {
		width: 100%;
	}

	.col-sm-12,
	.col-md-6 {
		padding-right: 15px;
		padding-left: 15px;
	}

	/*Filtros Tabela*/
	.dataTables_filter select.form-control {
		width: 322px;
		margin-top: 10px;
	}

	div.dataTables_wrapper div.dataTables_filter input {
		width: 147px;
	}

	.dataTables_wrapper .row div.col-sm-12.col-md-6:first-child,
	.dataTables_wrapper .row div.col-sm-12.col-md-6:last-child {
		max-width: 50%;
	}

	.dataTables_wrapper .row div.col-sm-12.col-md-6 .dataTables_filter {
		width: 154px;
	}

	/*Modal*/
	.modal-body {
		padding: 10px;
	}

	/*Tabs Levantamento*/
	.wizard .nav-pills .nav-item {
		width: 50%;
	}

	.wizard .nav-pills .nav-item .nav-link {
		padding: 10px 0;
	}
}

#result {
	border: 1px solid #888;
	background: #f7f7f7;
	padding: 1em;
	margin-bottom: 1em;
}

/*Footer*/
.footer .container-fluid .row {
	margin-left: 0;
	margin-right: 0;
}

.invalid-icon {
	padding-right: calc(1.5em + 0.75rem);
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: right calc(0.375em + 0.1875rem) center;
	background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.filter-input {
	width: 80px;
	height: 46px;
	background-color: rgba(222, 222, 222, 0.3);
	border: medium none;
}

@font-face {
	font-family: "Esquadgroup";
	src: url("../fonts/egf.otf") format("opentype");
}

.egf:before {
	font-family: "Esquadgroup";
	font-style: normal;
}

/*Icones --------------------------------------------------------*/

.egf.egf-componente:before {
	content: "C";
}

.egf.egf-perfil:before {
	content: "P";
}

.egf.egf-vidro:before {
	content: "V";
}

.is-invalid {
	border: 1px solid red !important;
}

.react-select__control svg {
	height: 48px !important;
}

.row-selected {
	background-color: #e8ffed !important;
}

.badge-secondary {
	background-color: #888;
}

.badge-dark {
	background-color: #212529;
}

@media (min-width: 1200px) {
	.modal-sl {
		max-width: calc(100% - 300px);
	}
}

.avatar {
	cursor: pointer;
}

.nav-item-focus {
	color: #09456b !important;
	font-weight: bold;
}

button.icon-primary {
	background: none;
	border: none;
}

.text-orange {
	color: orange;
}

.pointer {
	cursor: pointer;
}

.isContrast {
	border: 2px solid #09456b !important;
	font-weight: bold;
	background-color: #5ec1ff;
	color: #09456b;
}

.sidebar[data-active-color="primary"] .nav li.active > a,
.sidebar[data-active-color="primary"] .nav li.active > a i,
.sidebar[data-active-color="primary"]
	.nav
	li.active
	> a[data-toggle="collapse"],
.sidebar[data-active-color="primary"]
	.nav
	li.active
	> a[data-toggle="collapse"]
	i,
.sidebar[data-active-color="primary"]
	.nav
	li.active
	> a[data-toggle="collapse"]
	~ div
	> ul
	> li.active
	.sidebar-mini-icon,
.sidebar[data-active-color="primary"]
	.nav
	li.active
	> a[data-toggle="collapse"]
	~ div
	> ul
	> li.active
	> a,
.off-canvas-sidebar[data-active-color="primary"] .nav li.active > a,
.off-canvas-sidebar[data-active-color="primary"] .nav li.active > a i,
.off-canvas-sidebar[data-active-color="primary"]
	.nav
	li.active
	> a[data-toggle="collapse"],
.off-canvas-sidebar[data-active-color="primary"]
	.nav
	li.active
	> a[data-toggle="collapse"]
	i,
.off-canvas-sidebar[data-active-color="primary"]
	.nav
	li.active
	> a[data-toggle="collapse"]
	~ div
	> ul
	> li.active
	.sidebar-mini-icon,
.off-canvas-sidebar[data-active-color="primary"]
	.nav
	li.active
	> a[data-toggle="collapse"]
	~ div
	> ul
	> li.active
	> a {
	color: #1b6a9f !important;
}

.sidebar .nav li.active {
	background-color: #f5f7fb;
	border-right: solid 4px #41898c;
}

.sidebar .nav li > a {
	color: #57607a;
}

.sidebar .nav i,
.off-canvas-sidebar .nav i {
	color: #57607a;
}

.sidebar
	.sidebar-wrapper
	> .nav
	[data-toggle="collapse"]
	~ div
	> ul
	> li
	> a
	.sidebar-mini-icon,
.sidebar
	.sidebar-wrapper
	.user
	.info
	[data-toggle="collapse"]
	~ div
	> ul
	> li
	> a
	.sidebar-mini-icon,
.off-canvas-sidebar
	.sidebar-wrapper
	> .nav
	[data-toggle="collapse"]
	~ div
	> ul
	> li
	> a
	.sidebar-mini-icon,
.off-canvas-sidebar
	.sidebar-wrapper
	.user
	.info
	[data-toggle="collapse"]
	~ div
	> ul
	> li
	> a
	.sidebar-mini-icon {
	color: #57607a;
}

.sidebar .sidebar-wrapper li.active > a:not([data-toggle="collapse"]):before,
.sidebar .sidebar-wrapper li.active > a:not([data-toggle="collapse"]):after {
	display: none;
}

.main-panel {
	background-color: #f5f7fb;
}

.card {
	border-radius: 12px;
	box-shadow: 0px 0px 30px 5px rgba(198, 203, 212, 0.1);
	background-color: #ffffff;
	color: #252422;
	margin-bottom: 20px;
	position: relative;
	border: 0 none;
	border-bottom: #1b6a9f solid 14px;
	transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1),
		box-shadow 200ms ease;
}

.item-produto {
	border-bottom: none;
	border: solid 1px #abafbc !important;
}

.card-img,
.card-img-bottom {
	border-top-right-radius: 12px;
	border-top-left-radius: 12px;
}

.NavNova {
	font-size: 20px;
	color: #41898c;
	text-align: left !important;
	border: none !important;
	margin-left: -35px;
}

row col .float-right {
	text-align: left !important;
}

.card-parametro {
	border-bottom: none;
	border-radius: 10px;
	box-shadow: 0px 5px 5px rgba(198, 203, 212, 0.5) !important;
	padding-top: 15px;
}

.boxInput {
	border-radius: 10px !important;
	box-shadow: 0px 5px 5px rgba(198, 203, 212, 0.5) !important;
}

.boxButton {
	border-radius: 10px !important;
	box-shadow: 0px 5px 5px rgba(198, 203, 212, 0.5) !important;
	height: 50px !important;
	width: 84%;
}

.select__control {
	border-radius: 10px !important;
	height: 53px !important;
	border: 1px solid #dddddd !important;
}

.boxAvulco {
	border-radius: 10px !important;
	box-shadow: 0px 5px 5px rgba(198, 203, 212, 0.5) !important;
}

.boxButtonAvancar {
	border-radius: 10px !important;
	box-shadow: 0px 5px 5px rgba(198, 203, 212, 0.5) !important;
	height: 50px !important;
	width: 100%;
}

.notfButton {
	border-radius: 10px !important;
	box-shadow: 0px 5px 5px rgba(198, 203, 212, 0.5) !important;
	height: 50px !important;
}

.boxButton2 {
	border-radius: 10px !important;
	box-shadow: 0px 5px 5px rgba(198, 203, 212, 0.5) !important;
	background-color: rgba(78, 170, 99, 100%) !important;
}

.boxButton2 p {
	text-transform: none !important;
	font-size: 16px !important;
	display: flex;
	margin-left: 25px;
	height: 44px !important;
	margin-bottom: -25px;
}

.boxButton2 i {
	font-size: 24px !important;
	display: flex;
	margin-bottom: -20px;
	margin-left: -8px;
	margin-top: 0px;
}

.usuarioName {
	font-size: 12px;
	font-weight: 700 !important;
}

.usuarioClass {
	font-size: 10px;
	font-weight: 100 !important;
	text-align: right;
}

.divUsuario {
	margin-top: 35px !important;
}

.imgUsuario {
	border: 2px solid #1b6a9f;
	border-radius: 50%;
	width: 40px;
}

.bordaVertical {
	height: 53px;
	border-left: 1px solid #dfe3e9;

	margin-top: -16px;
}

.iconeCarrinho {
	font-size: 24px !important;
	display: block;
	margin-top: 15px !important;
	cursor: pointer;
}

.iconeAlerta {
	font-size: 24px !important;
	display: block;
	margin-top: 15px !important;
	cursor: pointer;
}

.iconeTroca {
	font-size: 24px !important;
	display: block;
	margin-top: 15px !important;
}

.spanTrocar {
	font-size: 14px;
	font-family: "Inter", sans-serif;
	color: #57607a !important;
	cursor: pointer;
}

.iconeBaixo {
	font-size: 12px;
	margin-top: 20px;
	margin-left: 5px;
}

.NotificacaoAlerta {
	background-color: #ff7913;
	color: #fff;
	border-radius: 50%;
	font-size: 10px !important;
	padding-left: 6px;
	padding-top: 2px;
	padding-right: 6px;
	padding-bottom: 0px;
}

.NotificacaoCarrinho {
	background-color: #3073cb;
	color: #fff;
	border-radius: 50%;
	font-size: 10px !important;
	padding-left: 6px;
	padding-top: 2px;
	padding-right: 6px;
	padding-bottom: 0px;
}

.boxSenha {
	border-radius: 10px !important;
	box-shadow: 0px 5px 5px rgba(198, 203, 212, 0.5) !important;
}

.boxSenha p {
	text-transform: none !important;
	font-size: 16px !important;
	margin-left: 25px;
	height: 44px !important;
	margin-bottom: -25px;
}

.boxSenha i {
	font-size: 24px !important;
	display: flex;
	margin-bottom: -20px;
	margin-left: -8px;
	margin-top: 0px;
}

.containerSub {
	position: absolute;
	left: 12%;
	top: 300px !important;
}

.imgLogin {
	position: absolute;
	left: 50%;
	top: 0%;
	height: 100vh !important;
}

.tituloLogin {
	font-size: 28px;
	font-weight: 700;
	color: #1b6a9f;
}

.inputLogin {
	width: 400px !important;
}

.buttonLogin {
	width: 400px !important;
	background-color: #3073cb;
	align-items: center;
}

.buttonLogin p {
	float: left;
	margin-top: auto;
	text-transform: none !important;
	font-size: 16px;
}

.buttonLogin i {
	float: right;
	margin: auto;
	font-size: 24px;
}

.bordaLogin {
	border: 1px solid #dfe3e9;
}

.buttonsLogin {
	position: absolute;
	left: 11%;
	top: 320px !important;
}

.buttonPrimeiro {
	display: flex;
	text-transform: none !important;
	background-color: #fff !important;
	color: #57607a;
	border: 1px solid #57607a;
	border-radius: 10px;
}

.buttonPrimeiro p {
	float: left;
	margin: auto;
	font-size: 14px;
}

.buttonPrimeiro i {
	float: left;
	margin: auto;
	font-size: 20px;
	color: #3073cb !important;
}

.buttonEsqueceu {
	display: flex;
	text-transform: none !important;
	background-color: #fff !important;
	color: #57607a;
	border: 1px solid #57607a;
	border-radius: 10px;
}

.buttonEsqueceu p {
	float: left;
	margin: auto;
	font-size: 14px;
}

.buttonEsqueceu i {
	float: left;
	margin: auto;
	font-size: 20px;
	color: #3073cb !important;
}

@media (min-width: 360px) {
	.imgLogin {
		display: none;
	}

	.containerSub {
		position: absolute;
		left: 9%;
		top: 100px !important;
	}

	.inputLogin {
		width: 300px !important;
	}

	.buttonsLogin {
		position: absolute;
		left: 33%;
		top: 320px !important;
	}

	.buttonLogin {
		width: 300px !important;
		background-color: #3073cb;
		align-items: center;
	}

	.buttonEsqueceu {
		margin-left: -35px;
	}

	.divUsuario {
		margin-top: -47px !important;
		margin-left: 7px;
		padding: 0 !important;
	}

	.navbar-wrapper {
		margin-top: -4px;
		margin-left: -15px;
		margin-bottom: 4px;
	}

	.usuarioName {
		font-size: 0px;
	}

	.usuarioClass {
		display: none;
	}
}

@media (min-width: 375px) {
	.imgLogin {
		display: none;
	}

	.containerSub {
		position: absolute;
		left: 10%;
		top: 100px !important;
	}

	.inputLogin {
		width: 300px !important;
	}

	.buttonsLogin {
		position: absolute;
		left: 33%;
		top: 320px !important;
	}

	.buttonLogin {
		width: 300px !important;
		background-color: #3073cb;
		align-items: center;
	}

	.buttonEsqueceu {
		margin-left: -35px;
	}
}

@media (min-width: 390px) {
	.navbar-wrapper {
		margin-top: -54px;
		margin-left: -15px;
		margin-bottom: 4px;
	}

	.divUsuario {
		margin-top: -24px !important;
		margin-left: 7px;
		padding: 0 !important;
	}
}

@media (min-width: 412px) {
	.imgLogin {
		display: none;
	}

	.containerSub {
		position: absolute;
		left: 14%;
		top: 150px !important;
	}

	.inputLogin {
		width: 300px !important;
	}

	.buttonsLogin {
		position: absolute;
		left: 33%;
		top: 320px !important;
	}

	.buttonLogin {
		width: 300px !important;
		background-color: #3073cb;
		align-items: center;
	}

	.buttonEsqueceu {
		margin-left: -35px;
	}

	.divUsuario {
		margin-top: -20px !important;
		margin-left: 7px;
		padding: 0 !important;
	}

	.navbar-wrapper {
		margin-top: -50px;
		margin-left: -15px;
	}

	.usuarioName {
		font-size: 0px;
	}

	.usuarioClass {
		display: none;
	}
}

@media (min-width: 414px) {
	.containerSub {
		position: absolute;
		left: 13%;
		top: 150px !important;
	}
	.salvarOrça {
		margin-left: -12rem;
		font-size: 18px;
	}
	.avancarBtn {
		margin-left: -2rem !important;
		font-size: 18px;
		background-color: rgb(48, 115, 203);
	}
	.voltarBtn {
		margin-left: 2rem !important;
		font-size: 18px;
		background-color: rgb(171, 175, 188);
	}
}

@media (min-width: 425px) {
	.imgLogin {
		display: none;
	}
	.navbar-wrapper {
		margin-top: -55px;
		margin-left: -5px;
	}
	.divUsuario {
		margin-top: -24px !important;
		margin-left: 7px;
		padding: 0 !important;
	}

	.containerSub {
		position: absolute;
		left: 15%;
		top: 100px !important;
	}

	.inputLogin {
		width: 300px !important;
	}

	.buttonsLogin {
		position: absolute;
		left: 33%;
		top: 320px !important;
	}

	.buttonLogin {
		width: 300px !important;
		background-color: #3073cb;
		align-items: center;
	}

	.buttonEsqueceu {
		margin-left: -35px;
	}
}

@media (min-width: 768px) {
	.imgLogin {
		display: none;
	}

	.containerLogin {
		width: 100% !important;
		height: 100% !important;
	}

	.containerSub {
		position: absolute;
		left: 30%;
		top: 150px !important;
	}

	.inputLogin {
		width: 300px !important;
	}

	.buttonsLogin {
		position: absolute;
		left: 33%;
		top: 320px !important;
	}

	.buttonLogin {
		width: 300px !important;
		background-color: #3073cb;
		align-items: center;
	}

	.buttonEsqueceu {
		margin-left: -35px;
	}

	.footerLogin {
		position: absolute;
		top: 10%;
	}

	.divUsuario {
		margin-top: -32px !important;
		margin-left: 7px;
		padding: 0 !important;
	}

	.navbar-wrapper {
		margin-top: -65px;
		margin-left: -10px;
	}

	.usuarioName {
		font-size: 14px;
		margin-right: 13px;
		margin-top: 19px;
	}

	.usuarioClass {
		text-align: right !important;
		display: grid;
	}

	.nomContato {
		margin-left: -1.6rem;
	}
	.cpfContato {
		margin-left: -1.6rem;
	}
	.cepContato {
		margin-left: -1.6rem;
	}
	.comContato {
		margin-left: -1.6rem;
	}
	.bntVoltar {
		margin-left: -1.6rem;
	}
	.btnSalvar {
		margin-left: -10.6rem;
	}
	.salvarOrça {
		margin-left: 1rem;
	}
	.avancarBtn {
		margin-left: -12rem !important;
	}
	.voltarBtn {
		margin-left: -1rem !important;
		font-size: 18px;
		background-color: rgb(171, 175, 188);
	}
}

@media (min-width: 992px) {
	.imgLogin {
		display: none;
	}

	.containerLogin {
		width: 100% !important;
		height: 100% !important;
	}

	.containerSub {
		position: absolute;
		left: 35%;
		top: 150px !important;
	}

	.inputLogin {
		width: 300px !important;
	}

	.buttonsLogin {
		position: absolute;
		left: 33%;
		top: 320px !important;
	}

	.buttonLogin {
		width: 300px !important;
		background-color: #3073cb;
		align-items: center;
	}

	.buttonEsqueceu {
		margin-left: -35px;
	}

	.footerLogin {
		position: absolute;
		top: 10%;
	}

	.divUsuario {
		margin-top: 34px !important;
		margin-left: 0px;
		padding: 0 !important;
	}

	.navbar-wrapper {
		margin-top: 0px;
		margin-left: 0px;
	}

	.usuarioName {
		font-size: 14px;
		margin-right: 0px;
		margin-top: 0px;
	}

	.usuarioClass {
		text-align: right !important;
		display: grid;
	}

	.nomContato {
		margin-left: -1.6rem;
	}
	.cpfContato {
		margin-left: -1.6rem;
	}
	.cepContato {
		margin-left: -1.6rem;
	}
	.comContato {
		margin-left: -1.6rem;
	}
	.bntVoltar {
		margin-left: -1.6rem;
	}
	.btnSalvar {
		margin-left: -10.6rem;
	}
}

@media (min-width: 1024px) {
	.imgLogin {
		display: none;
	}

	.containerLogin {
		width: 100% !important;
		height: 100% !important;
	}

	.containerSub {
		position: absolute;
		left: 35%;
		top: 100px !important;
	}

	.inputLogin {
		width: 300px !important;
	}

	.buttonsLogin {
		position: absolute;
		left: 33%;
		top: 320px !important;
	}

	.buttonLogin {
		width: 300px !important;
		background-color: #3073cb;
		align-items: center;
	}

	.buttonEsqueceu {
		margin-left: -35px;
	}

	.footerLogin {
		position: absolute;
		top: 10%;
	}
}

@media (min-width: 1240px) {
	.imgLogin {
		display: none;
	}

	.containerLogin {
		width: 100% !important;
		height: 100% !important;
	}

	.containerSub {
		position: absolute;
		left: 37%;
		top: 150px !important;
	}

	.inputLogin {
		width: 300px !important;
	}

	.buttonsLogin {
		position: absolute;
		left: 33%;
		top: 320px !important;
	}

	.buttonLogin {
		width: 300px !important;
		background-color: #3073cb;
		align-items: center;
	}

	.buttonEsqueceu {
		margin-left: -35px;
	}

	.footerLogin {
		position: absolute;
		top: 10%;
	}
}

@media (max-width: 1366px) {
	.boxButton {
		width: 100%;
	}
}

@media (min-width: 1366px) {
	.imgLogin {
		display: none;
	}

	.containerLogin {
		width: 100% !important;
		height: 100% !important;
	}

	.containerSub {
		position: absolute;
		left: 37%;
		top: 125px !important;
	}

	.inputLogin {
		width: 300px !important;
	}

	.buttonsLogin {
		position: absolute;
		left: 33%;
		top: 320px !important;
	}

	.buttonLogin {
		width: 300px !important;
		background-color: #3073cb;
		align-items: center;
	}

	.buttonEsqueceu {
		margin-left: -35px;
	}

	.footerLogin {
		position: absolute;
		top: 10%;
	}
	.card {
		width: 95%;
		margin-left: 30px;
	}
}

@media (min-width: 1440px) {
	.imgLogin {
		display: flex;
		width: 750px !important;
		height: 800px !important;
		position: absolute;
		top: -50px;
		bottom: -450px;
	}

	.containerLogin {
		width: 100% !important;
		height: 100% !important;
	}

	.containerSub {
		position: absolute;
		left: 12%;
		top: 150px !important;
	}

	.inputLogin {
		width: 300px !important;
	}

	.buttonsLogin {
		position: absolute;
		left: 33%;
		top: 320px !important;
	}

	.buttonLogin {
		width: 300px !important;
		background-color: #3073cb;
		align-items: center;
	}

	.buttonEsqueceu {
		margin-left: -35px;
	}

	.ImgFooter {
		display: none;
	}

	.footerLogin {
		display: none !important;
	}
}

@media (min-width: 1600px) {
	.imgLogin {
		display: flex;
		width: 980px !important;
		height: 1000px !important;
		position: absolute;
		top: -50px;
		bottom: -450px;
	}

	.containerLogin {
		width: 100% !important;
		height: 100% !important;
	}

	.containerSub {
		position: absolute;
		left: 12%;
		top: 250px !important;
	}

	.inputLogin {
		width: 300px !important;
	}

	.buttonsLogin {
		position: absolute;
		left: 33%;
		top: 320px !important;
	}

	.buttonLogin {
		width: 300px !important;
		background-color: #3073cb;
		align-items: center;
	}

	.buttonEsqueceu {
		margin-left: -35px;
	}

	.ImgFooter {
		display: none;
	}

	.footerLogin {
		display: none !important;
	}
}

@media (min-width: 1880px) {
	.imgLogin {
		display: flex;
		width: 960px !important;
		height: 980px !important;
		position: absolute;
		top: -50px;
		bottom: -450px;
	}

	.containerLogin {
		width: 100% !important;
		height: 100% !important;
	}

	.containerSub {
		position: absolute;
		left: 15%;
		top: 250px !important;
		width: 300px;
	}

	.inputLogin {
		width: 300px !important;
	}

	.buttonsLogin {
		position: absolute;
		left: 33%;
		top: 320px !important;
	}

	.buttonLogin {
		width: 300px !important;
		background-color: #3073cb;
		align-items: center;
	}

	.buttonEsqueceu {
		margin-left: -35px;
	}

	.ImgFooter {
		display: none;
	}

	.footerLogin {
		display: none !important;
	}
}

.ImgFooter {
	filter: brightness(0.5);
}

.ps {
	background-color: #f5f7fb;
}

.table-responsive-xl table thead tr th.th {
	width: auto !important;
	min-width: auto;
}

.input-group.valores {
	text-align: right;
}
.input-group-text.valores {
	background-color: #e9ecef !important;
	border: 1px solid #ced4da !important;
	padding: 10px !important;
}

input.valores {
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
	border-top-right-radius: 5px !important;
	border-bottom-right-radius: 5px !important;
}

.status-orcamento-detalhes {
	position: absolute;
	right: 20px;
	align-self: center;
}

.statusAvulso {
	display: flex;
	align-self: center;
	align-items: center;
	justify-items: end;
}
.status-orcamento-detalhes span {
	font-size: 18px;
}

.justify-content-right {
	justify-content: right !important;
}

.valores .form-control {
	display: block !important;
	width: 100% !important;
	height: calc(2.25rem + 2px) !important;
	padding: 0.375rem 0.75rem !important;
	font-size: 1rem !important;
	line-height: 1.5 !important;
	color: #495057 !important;
	background-color: #fff !important;
	background-clip: padding-box !important;
	border: 1px solid #ced4da !important;
	border-radius: 0.25rem !important;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.input-group.valores > .form-control {
	position: relative !important;
	-ms-flex: 1 1 auto !important;
	flex: 1 1 auto !important;
	width: 1% !important;
	margin-bottom: 0 !important;
}

.input-group.valores {
	position: relative !important;
	display: -ms-flexbox !important;
	display: flex !important;
	-ms-flex-wrap: wrap !important;
	flex-wrap: wrap !important;
	-ms-flex-align: stretch !important;
	align-items: stretch !important;
	width: 100% !important;
}

.btn-tipoPrecificacao {
	background-color: #fff;
	color: #51bcda !important;
	border: #51bcda solid;
}
.btn-tipoPrecificacao.active {
	background-color: #51bcda;
	color: #fff !important;
	border: solid transparent !important;
}
.btn-tipoPrecificacao:hover {
	border: solid transparent !important;
}

.hr {
  margin-top: -2rem;
  margin-bottom: 3rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  transform: rotate(90deg);
  margin-left: 11.5rem;
  width: 50px;
}

.colunas{
  display: flex;
  flex-direction: column-reverse;
}

.bottons {
  margin-left: -15rem;
  margin-top: 1.4rem;
  margin-bottom: -2.2rem;
}

.inputCheck {
  width: 24px;
  height: 24px;
  border: 1px solid black;
  margin-right: 1rem;
}

.label1 {
  font-size: 12px;
  color: #57607A;
  margin-top: 2px;
  margin-right: 0.5rem;
}

.label2 {
  font-size: 12px;
  color: #57607A;
  margin-top: 2px;
  margin-right: 0.5rem;
}

.ordenar {
  margin-left: 13rem;
  margin-top: -3.6rem;
  margin-bottom: 1.5rem;
}

.inputOrde {
  margin-left: 2.5rem;
  margin-top: 0rem;
  width: 150px !important;
}

.inp {
  position: absolute;
  top: 2px;
  left: 50px;
  height: 50px !important;
  width: 300px !important;
  border-radius: 10px !important;
  box-shadow: 0px 5px 5px rgb(198 203 212 / 50%) !important;
  font-family: 'Inter', sans-serif !important;
  color: #57607A !important;
  border: 0 none !important;
  outline: 0 !important;
}

.btn {
  background-color:#09456b !important;
  border-radius: 10px !important;
  box-shadow: 0px 5px 5px rgb(198 203 212 / 50%) !important;
  margin-top: 5rem;

  width: 100%;
}

.btn i {
  font-size: 24px !important;
  display: flex;
  margin-bottom: -20px;
  margin-left: -8px;
  margin-top: 0px;
}

.btn p {
  text-transform: none !important;
  font-size: 16px !important;
  display: flex;
  margin-left: 25px;
  height: 44px !important;
  margin-bottom: -25px;
}

.checkPes {
  position: absolute;
  top: -12px;
  left: 60px;
  height: 50px !important;
  width: 300px !important;
  border-radius: 10px !important;
  box-shadow: 0px 5px 5px rgb(198 203 212 / 50%) !important;
  font-family: 'Inter', sans-serif !important;
  color: #57607A !important;
  border: 0 none !important;
  outline: 0 !important;

}
.desc{
    text-Align:end;
    align-content: end;
    justify-content: end;
}

.filtro{
    display: flex;
    width: 400;
    margin: 0%;
    padding-right: 0%;
    padding-left: 0%;
    align-items: end;
    justify-content: center;
    padding-bottom: 15px;
}
.cabecalho{
    align-items:center;
    justify-content:space-between;
    margin: 0%;
}
.pesquisa{
    margin-right: 10px;
    font-size: 22px;
    color: #1B6A9F;
    align-self: center;
    align-self: center;
}
.linha{
    border-bottom: 1px solid #bdc0c7;
    margin-top: 20px;
    padding-bottom: 10px;
    align-items: baseline;
}
.total {
  display: flex;
  margin-top: -3.3rem;
  justify-content: end;
  margin-bottom: 2rem;
  margin: 0;
  padding: 0;
  color: #57607A;
}

.title {
    font-size: 20px;
    font-family: sans-serif;
    margin-left: -.8rem;
    margin: 0;
    padding: 0;
    color: #57607A;
}
.title strong{
    color: #1B6A9F;
}



@media(min-width: 1440px){
    .selecao{
        margin-left: 3rem;
    }

}
@media(min-width: 2560px){
    .selecao{
        margin-left: 34.5rem;
    }

}

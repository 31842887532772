@font-face {
    font-family: 'icomoon';
    src:  url('fonts/icomoon.eot?8153l6');
    src:  url('fonts/icomoon.eot?8153l6#iefix') format('embedded-opentype'),
      url('fonts/icomoon.ttf?8153l6') format('truetype'),
      url('fonts/icomoon.woff?8153l6') format('woff'),
      url('fonts/icomoon.svg?8153l6#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-p-ok:before {
    content: "\e900";
  }
  .icon-p-cancel:before {
    content: "\e901";
  }
  .icon-o-revisado:before {
    content: "\e902";
  }
  .icon-o-enviado:before {
    content: "\e903";
  }
  .icon-nf:before {
    content: "\e904";
  }
  .icon-o-anexo:before {
    content: "\e905";
  }
  .icon-o-list:before {
    content: "\e906";
  }
  .icon-itens:before {
    content: "\e907";
  }
  .icon-p-busca:before {
    content: "\e909";
  }
  .icon-p-aguardando:before {
    content: "\e90a";
  }
  .icon-p-enviado:before {
    content: "\e90b";
  }
  .icon-busca:before {
    content: "\e90c";
  }
  .icon-mais:before {
    content: "\e90d";
  }
  .icon-esquerda:before {
    content: "\e90e";
  }
  .icon-voltar:before {
    content: "\e90f";
  }
  .icon-espera:before {
    content: "\e910";
  }
  .icon-avancar:before {
    content: "\e911";
  }
  .icon-ajuda:before {
    content: "\e912";
  }
  .icon-o-espera:before {
    content: "\e913";
  }
  .icon-o-cancel:before {
    content: "\e914";
  }
  .icon-down:before {
    content: "\e915";
  }
  .icon-pedidos:before {
    content: "\e916";
  }
  .icon-up:before {
    content: "\e917";
  }
  .icon-dashborad:before {
    content: "\e918";
  }
  .icon-diretita:before {
    content: "\e919";
  }
  .icon-orcamentos:before {
    content: "\e91a";
  }
  .icon-menos:before {
    content: "\e91b";
  }
  .icon-clientes:before {
    content: "\e91c";
  }
  .icon-h:before {
    content: "\e91d";
  }
  .icon-add-cliente:before {
    content: "\e91e";
  }
  .icon-ckeck:before {
    content: "\e91f";
  }
  .icon-troca:before {
    content: "\e920";
  }
  .icon-fecha:before {
    content: "\e921";
  }
  .icon-atualiza-val:before {
    content: "\e922";
  }
  .icon-imprime:before {
    content: "\e923";
  }
  .icon-lista:before {
    content: "\e924";
  }
  .icon-copia:before {
    content: "\e925";
  }
  .icon-perfil:before {
    content: "\e926";
  }
  .icon-edita:before {
    content: "\e927";
  }
  .icon-esquadria:before {
    content: "\e928";
  }
  .icon-baixa:before {
    content: "\e929";
  }
  .icon-obra:before {
    content: "\e92a";
  }
  .icon-sai:before {
    content: "\e92b";
  }
  .icon-cor:before {
    content: "\e92c";
  }
  .icon-abre:before {
    content: "\e92d";
  }
  .icon-dinheiro:before {
    content: "\e92e";
  }
  .icon-carrinho:before {
    content: "\e92f";
  }
  .icon-ver-janela:before {
    content: "\e930";
  }
  .icon-adc-carrinho:before {
    content: "\e931";
  }
  .icon-ver:before {
    content: "\e932";
  }
  .icon-carrinho-aberto:before {
    content: "\e933";
  }
  .icon-estrela:before {
    content: "\e934";
  }
  .icon-alerta:before {
    content: "\e935";
  }
  .icon-chat-star:before {
    content: "\e936";
  }
  .icon-lixeira:before {
    content: "\e937";
  }
  .icon-envia:before {
    content: "\e938";
  }
  .icon-edita-salva:before {
    content: "\e939";
  }
  .icon-o-ok:before {
    content: "\e93a";
  }
  .icon-edita-info:before {
    content: "\e93b";
  }
  .icon-o-edita:before {
    content: "\e93c";
  }
  .icon-pdf:before {
    content: "\e93d";
  }
  .icon-o-recebido:before {
    content: "\e93e";
  }
  .icon-info:before {
    content: "\e93f";
  }
  .icon-o-busca:before {
    content: "\e940";
  }
  .icon-inco-2:before {
    content: "\e941";
  }
  .icon-o-up:before {
    content: "\e942";
  }
  
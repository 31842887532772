.title{
    font-size: 24px;
    font-weight: 700;
    font-family: 'Inter', sans-serif;
    color: #1B6A9F;
}

.pDesc{
    font-family: 'Inter', sans-serif !important;
    font-size: 14px;
    color: #57607A !important;
}

.box{
    border-radius: 0px 10px 10px 0px;
    box-shadow: 0px 5px 5px rgb(198 203 212 / 50%) !important;
}

.button{
    box-shadow: 0px 5px 5px rgb(198 203 212 / 50%) !important;
    border-radius: 10px;
    background-color: #1B6A9F;
    height: 40px;
    color: #fff;
}

.desc{
    font-size: 12px;
    text-align: center;
}
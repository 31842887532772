.title {
    font-size: 20px;
    font-weight: 700px;
    color: #57607A;
}

.strong {
    font-size: 20px;
    font-weight: bold;
    color: #048fc1db;
}

.bAvulso {
    width: 190px;
    height: 44px;
    background-color: #4aa374;
    color: #fff;
    border-radius: 8px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.p {
    color: #FFFF;
    font-size: 16px;
    font-weight: 700;
}

.bImportar {
    width: 147px;
    height: 44px;
    background-color: #57607A;
    color: #fff;
    display: flex;
    padding: 10px;
    margin-top: -5px;
}

.iconeP {
    font-size: 24px;
}

.iconeUp {
    font-size: 24px;
}

.pedido {
    font-size: 28px;
    color: #4EAA79;
    margin-left: 10px;
    font-weight: 700;
    margin-top: 15px;
}

.pedido2 {
    font-size: 28px;
    color: #1B6A9F;
    margin-left: 10px;
    font-weight: 700;
    margin-top: 15px;
}

.linha {
    width: 100%;
    border: 1px solid #ABAFBC;

}

.linha2 {
    width: 1345px;
    border: 1px solid #ABAFBC;
    margin: -37px 0px 0px 290px;
}

.box {
    width: 795px;
    height: 196px;
    padding: 15px 0px 0px 0px;
    padding-left: 0px !important;
    padding-right: 0px !important;
    border: 1px solid #ABAFBC;
    margin-left: 30px;
    margin-top: 40px;
}

.box1 {
    width: 100% !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    border: 1px solid #ABAFBC;
}


.pPedido {
    margin: 9px 0px 0px 0px;
    font-size: 16px;
    color: #57607A;
}

.pCancela {
    margin: 9px 0px 0px 0px;
    font-size: 16px;
    color: #57607A;
}

.pEnviado {
    font-size: 16px;
    color: #57607A;
}

.linhaH {
    height: 40px;
    border: 1px solid #DFE3E9;
    margin-right: 10px;
    margin-left: 10px;
}

.iconeLa {
    font-size: 20px;
    color: #3073CB;
    margin: 10px 5px 0px 5px;
}

.iconeVer {
    font-size: 20px;
    color: #57607A;
    margin: 10px 5px 0px 5px;
    cursor: pointer;
}


.iconeLi {
    font-size: 20px;
    color: #57607A;
    margin: 10px
}

.linhaV {
    width: 240px;
    border: 1px solid #DFE3E9;
}


.cor {
    color: #57607A;
}

.produtos {
    color: #57607A;
    font-size: 16px;
    font-weight: 700;
}

.custo {
    color: #3073CB;
    font-size: 14px;
    font-weight: 400;
}

.total {
    font-size: 28px;
    color: #57607A;
    font-weight: 700;
}

.bordaButton {
    height: 14px;
    width: 897px;
    background-color: #4EAA79;
    border-radius: 0px 0px 10px 10px;
    margin-top: 3px;
}

.bordaButton2 {
    height: 14px;
    width: 897px;
    background-color: #1B6A9F;
    border-radius: 0px 0px 10px 10px;
    margin-top: 3px;
}

.historico {
    font-size: 20px;
    font-weight: 700;
    color: #57607A;
}

.input {
    border: 1px solid #57607A;
    width: 187px;
    padding: 10px;
    border-radius: 10px;
    height: 47px;
    margin-top: -15px;
    color: #57607A;
}

.data {
    display: flex;
    flex-direction: column;

}
.date{

    font-size: 14px;
    color: #048fc1db;
    font-weight: 700;
}

.subtitle {
    font-size: 12px;
    font-weight: 400;
}

.item {
    margin: 10px 0px;
    padding: 8px 0px;
    border: 1px solid #bdbfc6;
    border-left: 4px solid #048fc1db;
    border-radius: 10px;
    align-items: center;
}

.inputCheck {
    width: 24px;
    height: 24px;
    border: 1px solid black;
}

.label1 {
    font-size: 12px;
    color: #57607A;
    margin-top: 2px;
    margin-right: 0.5rem;
}

.label2 {
    font-size: 12px;
    color: #57607A;
    margin-top: 2px;
    margin-right: 0.5rem;
}

.foOrca {
    display: flex;
    align-items: center;
}
.pCota {
    display: flex;
    align-items: center;
}

.bottons {
    margin-left: 53rem;
    margin-right: -55rem;
    margin-top: -3.5rem;
}

.hr {
    margin-top: -1rem;
    margin-bottom: 3rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    transform: rotate(90deg);
    margin-left: 11.5rem;
    width: 50px;
}

.ordenar {
    margin-left: 13rem;
    margin-top: -3.8rem;
    margin-bottom: 1.5rem;
}


@media (min-width: 390px) {
    .title {
        margin-left: -1.4rem;
        font-size: 20px;
    }

    .hr {
        margin-left: 9.5rem;
    }

    .ordenar {
        margin-left: 11rem;
        margin-top: -4.8rem;
        margin-bottom: 1.9rem;
    }

    .inputOrde {
        margin-left: -2.2rem;
        margin-top: 2.2rem;
        width: 120px !important;
    }

    .bottons {
        margin-left: 5rem;
        margin-right: -21rem;
        margin-top: -3.5rem;
    }

    .labell {
        margin-top: 4rem !important;
        margin-left: -1rem;
        margin-right: 1.3rem;
    }

    .foOrca {
        margin-left: -17.2rem !important;
        margin-top: 5.3rem;
    }

    .pesquisaa {
        width: 195px !important;
    }

    .iWidth {
        width: 195px !important;
    }

}

@media (min-width: 414px) {

    .foOrca {
        margin-left: -16.2rem !important;
        margin-top: 5.3rem;
    }

    .labell {
        margin-top: 4rem !important;
        margin-left: 0rem;
        margin-right: 0.3rem;
    }
}

@media (min-width: 768px) {
    .labell {
        margin-top: 4rem !important;
        margin-left: 11rem;
        margin-right: -10.7rem;
    }

    .foOrca {
        margin-left: -5.5rem !important;
        margin-top: 5.3rem;
    }

    .ordenar {
        margin-top: -3.8rem;
    }

    .inputOrde {
        margin-left: 2.5rem;
        margin-top: 0rem;
        width: 120px !important;
    }

    .title {
        margin-left: -1rem;
        font-size: 20px;
    }
}

@media (min-width: 992px) {
    .labell {
        margin-top: 1.5rem !important;
        margin-left: 8rem;
        margin-right: -7.7rem;
    }

    .foOrca {
        margin-left: 7.5rem !important;
        margin-top: -0.1rem;
    }

    .pesquisaa {
        width: 140px !important;
    }

    .iWidth {
        width: 130px !important;
    }

    .inputOrde {
        margin-left: 2.5rem;
        margin-top: 0rem;
        width: 150px !important;
    }
}

@media (min-width: 1024px) {
    .iWidth {
        width: 150px !important;
    }
}

@media (min-width: 1240px) {
    .foOrca {
        margin-left: 17.7rem !important;
        margin-top: -0.1rem;
    }

    .pesquisaa {
        width: 150px !important;
    }

    .labell {
        margin-top: 1.5rem !important;
        margin-left: 16.5rem;
        margin-right: -15.7rem;
    }
}

@media (min-width: 1280px) {
    .labell {
        margin-top: 1.5rem !important;
        margin-left: 17.5rem;
        margin-right: -15.7rem;
    }
}

@media (min-width: 1366px) {
    .pesquisaa {
        width: 180px !important;
    }

    .iWidth {
        width: 180px !important;
    }

    .bottons {
        margin-left: 11rem;
        margin-right: -21rem;
        margin-top: -3.5rem;
    }

    .labell {
        margin-top: 1.5rem !important;
        margin-left: 16.5rem;
        margin-right: -15.7rem;
    }

    .foOrca {
        margin-left: 18.7rem !important;
        margin-top: -0.1rem;
    }
}

@media (min-width: 1440px) {
    .bottons {
        margin-left: 15.5rem;
        margin-right: -21rem;
        margin-top: -3.5rem;
    }
}

@media (min-width: 1600px) {
    .bottons {
        margin-left: 24.5rem;
        margin-right: -21rem;
        margin-top: -3.5rem;
    }
}

@media (min-width: 1800px) {
    .bottons {
        margin-left: 36rem;
        margin-right: -21rem;
        margin-top: -3.5rem;
    }
}

@media (min-width: 1880px) {
    .bottons {
        margin-left: 40.7rem;
        margin-right: -21rem;
        margin-top: -3.5rem;
    }
}

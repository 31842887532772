main {
    align-items: none;
    background: none;
    border-radius: none;
    box-shadow: none;
    font-family: 'Inter', sans-serif;
}



.icons {
    color: #41898C;
}

.card-footer {
    height: 110px;
    border-radius: 0px 0px 10px 10px;
}

.card {
    border-radius: 10px !important;
}

.card-stats {
    width: 220px;
    margin-left: 20px;
}

.letra {
    color: #57607A;
    font-size: 16px;
    margin-left: -10px;
}

.i {
    margin-top: 32px !important;
}

b {
    display: block;
}

.client::placeholder {
    color: rgba(87, 96, 122, 0.5);
}

.place::placeholder {
    color: rgba(87, 96, 122, 0.5);
}

.date::placeholder {
    color: rgb(87, 96, 122)
}

.imagemm1 {
    width: 50px;
    background: rgba(30, 48, 85, 0.1);
    color: rgba(30, 48, 85, 0.1);
    padding: 5px;
    padding-top: 7px;
    border-radius: 10px;
    padding-left: 12px;
}

.imagemm1 .icon-icon-o-cancel:before {
    content: "\e914";
    font-size: 34px;
    color: rgba(30, 48, 85, 1);
}

.b1 {
    color: #57607A;
}

.imagemm2 {
    width: 50px;
    background: rgba(48, 139, 203, 0.1);
    padding: 5px;
    padding-top: 7px;
    border-radius: 10px;
    padding-left: 10px;
}

.imagemm2 i {
    font-size: 30px !important;
    color: #308BCB
}

.b2 {
    color: #308BCB;
}

.imagemm3 {
    width: 50px;
    background: rgba(78, 170, 99, 0.1);
    padding: 5px;
    padding-top: 7px;
    border-radius: 10px;
    padding-left: 10px;
}

.imagemm3 i {
    font-size: 30px !important;
}

.b3 {
    color: #4EAA63;
}


.imagemm4 {
    width: 50px;
    background: rgba(214, 54, 104, 0.1);
    padding: 5px;
    padding-top: 7px;
    border-radius: 10px;
    padding-left: 13px;
}

.imagemm4 i {
    font-size: 30px !important;
}

.b4 {
    color: #D63668;
}

.imagemm5 {
    width: 50px;
    background: rgba(123, 52, 204, 0.1);
    padding: 5px;
    padding-top: 7px;
    border-radius: 10px;
    padding-left: 13px;
}

.imagemm5 i {
    font-size: 30px !important;
}

.b5 {
    color: #7B34CC;
}

.imagemm6 {
    width: 50px;
    background: rgba(243, 130, 44, 0.1);
    padding: 5px;
    padding-top: 7px;
    border-radius: 10px;
    padding-left: 13px;
}

.imagemm6 i {
    font-size: 30px !important;
}

.b6 {
    color: #F3822C;
}

.numeros p {
    font-size: 26px;
    color: #57607A;
    font-weight: bold;
    margin-left: 36px;
    margin-top: 8px;
}

.bord1 {
    border: 1px solid #1E3055;
}

.borde1 {
    margin-right: 0;
    margin-top: -10px;
    margin-bottom: 0px;
    margin-left: 0;
    border-top: 10px solid #1E3055;
    border-radius: 0px 0px 10px 10px;
}

.bord2 {
    border: 1px solid #308BCB;
}

.borde2 {
    margin-right: 0;
    margin-top: -10px;
    margin-bottom: 0px;
    margin-left: 0;
    border-top: 10px solid #308BCB;
    border-radius: 0px 0px 10px 10px;
}

.bord3 {
    border: 1px solid #4EAA63;
}

.spanColor {
    color: #4EAA63
}

.borde3 {
    margin-right: 0;
    margin-top: -10px;
    margin-bottom: 0px;
    margin-left: 0;
    border-top: 10px solid #4EAA63;
    border-radius: 0px 0px 10px 10px;
}

.bord4 {
    border: 1px solid #D63668;
}

.borde4 {
    margin-right: 0;
    margin-top: -10px;
    margin-bottom: 0px;
    margin-left: 0;
    border-top: 10px solid #D63668;
    border-radius: 0px 0px 10px 10px;
}

.bord5 {
    border: 1px solid #7B34CC;
}

.borde5 {
    margin-right: 0;
    margin-top: -10px;
    margin-bottom: 0px;
    margin-left: 0;
    border-top: 10px solid #7B34CC;
    border-radius: 0px 0px 10px 10px;
}

.bord6 {
    border: 1px solid #F3822C;
}

.borde6 {
    margin-right: 0;
    margin-top: -10px;
    margin-bottom: 0px;
    margin-left: 0;
    border-top: 10px solid #F3822C;
    border-radius: 0px 0px 10px 10px;
}

.col-lg-6 {
    text-align: center;
    max-width: 738px !important;
    max-height: 506px !important;
    margin-left: 20px;
    padding: 0;
    margin-right: -27px;
}

strong {
    color: #57607A;
}

.borde-1 {
    margin-right: 0;
    margin-top: -10px;
    margin-bottom: 0px;
    margin-left: 0;
    border-top: 10px solid #41898C;
    border-radius: 0px 0px 10px 10px;
}

.p2 {
    margin-left: 65px !important;
}

.svg {
    margin: -13px 0px 0px 61px;
    height: 120px;
    width: 120px;
    transform: rotate(312deg);
    border: 10px solid white;
}

.chart1 {
    margin-bottom: -100px;
}

.chartss {
    margin-top: -30px;
    margin-bottom: 13px;
}

.recent {
    color: #41898C
}

.google-visualization-table-seq {
    text-align: center !important;
}

.google-visualization-table-table td {
    text-align: left;
}

.google-visualization-table-td.google-visualization-table-type-number {
    text-align: center !important;
    white-space: nowrap;
}

.google-visualization-table-th gradient unsorted {
    text-align: left !important;
}



@media (min-width: 375px) {
    .react-datepicker__close-icon {
        height: 60% !important;
        position: absolute;
        margin: 0px 155px 0px 0px;
    }

    .card-stats {
        width: 20.2rem !important;
    }

    .numeros p {
        margin-left: 50px;
    }
    .letra {
        margin-left: 0px
    }

    .chart1 {
        text-align: center;
    }

}

@media (min-width: 414px) {
    .card-stats {
        width: 22.8rem !important;
    }

    .cardsPizzas {
        margin-left: 1.3rem;
        width: 332px !important;
    }

    .mx-n7 {
        margin-left: -1.9rem !important;
    }

    .tabelasss {
        width: 363px !important;
        margin-left: .5rem !important;
    }

    .tabela1 {
        width: 363px !important;
        margin-left: .5rem !important;
    }

    .tabelas2 {
        width: 363px !important;
        margin-left: .5rem !important;
    }

}

@media (min-width: 425px) {
    .react-datepicker__close-icon {
        height: 60% !important;
        position: absolute;
        margin: 0px 205px 0px 0px;
    }

    .numeros p {
        margin-left: 49px;
    }

    .chart1 {
        text-align: center;
    }

    .card-stats {
        width: 23rem !important;
    }

    .cardsPizzas {
        margin-left: 1.5rem;
        width: 332px !important;
    }

    .mx-n7 {
        margin-left: -1.7rem !important;
    }

    .tabelasss {
        width: 363px !important;
        margin-left: 0.9rem !important;
    }

    .tabela1 {
        width: 363px !important;
        margin-left: 0.9rem !important;
    }

    .tabelas2 {
        width: 363px !important;
        margin-left: 0.9rem !important;
    }

}

@media (min-width: 768px) {
    .react-datepicker__close-icon {
        height: 60% !important;
        position: absolute;
        margin: 0px 525px 0px 0px;
    }

    .numeros p {
        margin-left: 100px;
    }

    .card-stats {
        width: 20rem !important;
        margin-left: 20px !important;
    }
    

    .tops {
        width: 600px !important;
    }

    .chart1 {
        text-align: center;
    }

    .cardsPizzas {
        margin-left: 2.5rem;
        width: 38.5rem !important;
    }

    .mx-n7 {
        margin-left: -1.7rem !important;
    }

    .tabelasss {
        width: 42.5rem !important;
        margin-left: 0.8rem !important;
    }

    .tabela1 {
        width: 42.5rem !important;
        margin-left: 0.8rem !important;
    }

    .tabelas2 {
        width: 42.5rem !important;
        margin-left: 0.8rem !important;
    }

    .chatsTable {
        margin-left: auto;
        margin-right: auto;
    }

}

@media (max-width: 768px) {
    .col-sm-12 {
        margin-left: 25px
    }
}

@media (min-width: 992px) {
    .react-datepicker__close-icon {
        height: 60% !important;
        position: absolute;
        margin: 0px -95px 0px 0px;
    }

    .card-stats {
        width: 15rem !important;
        margin-left: 13px !important;
    }

    .numeros p {
        margin-left: 50px;
    }

    .chartss {
        margin-bottom: -2px;
    }

    .tops {
        width: 329px !important;
    }

    .chartPizza {
        margin-left: -0.1rem;
    }

    .cardsPizzas {
        margin-left: 2.5rem;
        width: 44.5rem !important;
    }

    .mx-n7 {
        margin-left: -1.7rem !important;
    }

    .tabelasss {
        width: 49.6rem !important;
        margin-left: .3rem !important;
    }

    .tabela1 {
        width: 49.6rem !important;
        margin-left: .3rem !important;
    }

    .tabelas2 {
        width: 49.6rem !important;
        margin-left: .3rem !important;
    }

    .fontC {
        font-size: 14px
    }

}

@media (min-width: 1024px) {
    .react-datepicker__close-icon {
        height: 60% !important;
        position: absolute;
        margin: 0px -90px 0px 0px;
    }

    .chartPizza {
        margin-left: -0.1rem;
    }

    .cardsPizzas {
        margin-left: 2.5rem;
        width: 46rem !important;
    }

    .mx-n7 {
        margin-left: -1.7rem !important;
    }

    .tabelasss {
        width: 51rem !important;
        margin-left: 0.3rem !important;
    }

    .tabela1 {
        width: 51rem !important;
        margin-left: 0.3rem !important;
    }

    .tabelas2 {
        width: 51rem !important;
        margin-left: 0.3rem !important;
    }

    .fontC {
        font-size: 15px
    }

    .card-stats {
        width: 15rem !important;
        margin-left: 13px !important;
    }

    .numeros p {
        margin-left: 12px;
    }

    .chartss {
        width: 100%;
    }
}

@media (min-width: 1240px) {
    .react-datepicker__close-icon {
        height: 60% !important;
        position: absolute;
        margin: 0px -55px 0px 0px;
    }

    .chartPizza {
        margin-left: -7.6rem;
    }

    .cardsPizzas {
        margin-left: 0.8rem;
        width: 20rem !important;
    }

    .mx-n7 {
        margin-left: -1.7rem !important;
    }

    .tabelasss {
        width: 31.5rem !important;
        margin-left: 0rem !important;
    }

    .tabela1 {
        width: 31.5rem !important;
        margin-left: 1.93rem !important;
    }

    .tabelas2 {
        width: 31.5rem !important;
        margin-left: 0rem !important;
    }
    .card-stats {
        width: 11rem !important;
        margin-left: 0.5rem !important;
    }

    .col-xl-6 {
        max-width: 738px !important;
        max-height: 506px !important;
    }

    .card-header,
    .card-body {
        width: 100%;
    }
}

@media (min-width: 1366px) {
    .cardsStatus {
        margin-right: 0px !important;
    }

    .chartPizza {
        margin-left: -.7rem;
    }

    .cardsPizzas {
        margin-left: 0.8rem !important;
        width: 23rem !important;
    }

    .mx-n7 {
        margin-left: -1.7rem !important;
    }

    .tabelasss {
        width: 36rem !important;
        margin-left: 0rem !important;
    }

    .tabela1 {
        width: 36rem !important;
        margin-left: 1.3rem !important;
    }

    .tabelas2 {
        width: 36rem !important;
        margin-left: 0rem !important;
    }

    .react-datepicker__close-icon {
        height: 60% !important;
        position: absolute;
        margin: 0px -33px 0px 0px;
    }

    .card-stats {
        width: 12rem !important;
    }
}

@media (min-width: 1440px) {
    .react-datepicker__close-icon {
        height: 60% !important;
        position: absolute;
        margin: 0px -20px 0px 0px;
    }

    .numeros p {
        margin-left: 47px;
    }

    .cardsPizzas {
        margin-left: 0.8rem !important;
        width: 24rem !important;
    }

    .mx-n7 {
        margin-left: -1.7rem !important;
    }

    .tabelasss {
        width: 38rem !important;
        margin-left: 0rem !important;
    }

    .tabela1 {
        width: 38rem !important;
        margin-left: 1.68rem !important;
    }

    .tabelas2 {
        width: 38rem !important;
        margin-left: 0rem !important;
    }

    .tBudget {
        font-size: 16px;
    }

    .chartPizza {
        margin-left: .5rem;
    }

    .card-stats {
        width: 13rem !important;
    }

    .letra {
        margin-left: 0px
    }

}

@media (min-width: 1600px) {
    .react-datepicker__close-icon {
        height: 60% !important;
        position: absolute;
        margin: 0px 5px 0px 0px;
    }

    .card-stats {
        width: 196px !important;
        height: 177px !important;
    }

    .numeros p {
        margin-left: 47px;
    }

    .div {
        margin-left: -50px;
    }

    .card-stats {
        margin-left: 20px;
        width: 226px !important;
        height: 177px !important;
    }

    .letra {
        margin-left: 0px
    }

    .cardsPizzas {
        margin-left: 0.8rem !important;
        width: 27rem !important;
    }

    .mx-n7 {
        margin-left: -1.7rem !important;
    }

    .tabelasss {
        width: 42rem !important;
        margin-left: 0rem !important;
    }

    .tabela1 {
        width: 42rem !important;
        margin-left: 2.3rem !important;
    }

    .tabelas2 {
        width: 42rem !important;
        margin-left: 0rem !important;
    }

}

@media (min-width: 1800px) {
    .cardsPizzas {
        margin-left: 0.8rem !important;
        width: 29rem !important;
    }

    .mx-n7 {
        margin-left: -1.7rem !important;
    }

    .tabelasss {
        width: 48.3rem !important;
        margin-left: 0rem !important;
    }

    .tabela1 {
        width: 48.3rem !important;
        margin-left: -4.5rem !important;
    }

    .tabelas2 {
        width: 48.3rem !important;
        margin-left: 0rem !important;
    }
}

@media (min-width: 1880px) {
    .card-stats {
        width: 17rem !important;
    }

    .numeros p {
        margin-left: 47px;
    }

    .letra {
        margin-left: 0px
    }

    .cardsStatus {
        margin-right: -80px !important;
    }

    .cardsPizzas {
        margin-left: 0.8rem !important;
        width: 33rem !important;
    }

    .mx-n7 {
        margin-left: -1.7rem !important;
    }

    .tabelasss {
        width: 48.3rem !important;
        margin-left: 0rem !important;
    }

    .tabela1 {
        width: 51.45rem !important;
        margin-left: -5.7rem !important;
    }

    .tabelas2 {
        width: 48.3rem !important;
        margin-left: 0rem !important;
    }

    .react-datepicker__close-icon {
        position: absolute;
        margin: 0px 55px 0px 0px;
    }

    .tabelasss {
        width: 823.18px !important;
    }

    .tabelas2 {
        width: 821.17px !important;
    }

}

.google-visualization-table-tr-head {
    background-color: #fff !important;
    font-weight: bold;
    text-align: center;
}

.google-visualization-table-table th {
    border: none !important;
    border-width: 0 1px 1px 0;
    padding: 2px 0.35em;
    position: relative;
    z-index: 10;
}

.google-visualization-table-th gradient unsorted {
    text-align: left !important;
}

.react-datepicker__close-icon {
    height: 60% !important;
}

.mx-n7 {
    margin-left: -2rem;
}

table tr {
    font-family: 'Inter', sans-serif;
    color: #57607A;
  }

  Label {
    font-family: 'Inter', sans-serif;
    color: #57607A;
  }

  @font-face {
    font-family: 'icomoon';
    src: url('fonts/icomoon.eot?ot1k80');
    src: url('fonts/icomoon.eot?ot1k80#iefix') format('embedded-opentype'),
      url('fonts/icomoon.ttf?ot1k80') format('truetype'),
      url('fonts/icomoon.woff?ot1k80') format('woff'),
      url('fonts/icomoon.svg?ot1k80#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  [class^="icon-"],
  [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-icon-orderby:before {
    content: "\e899";
  }

  .icon-icon-p-ok:before {
    content: "\e900";
    color: #4EAA63;
  }

  .icon-icon-p-cancel:before {
    content: "\e901";
    color: #D63668
  }

  .icon-icon-o-revisado:before {
    content: "\e902";
  }

  .icon-icon-o-enviado:before {
    content: "\e903";
    color: red;
  }

  .icon-icon-nf:before {
    content: "\e904";
  }

  .icon-icon-o-anexo:before {
    content: "\e905";
  }

  .icon-icon-o-list:before {
    content: "\e906";
  }

  .icon-icon-itens:before {
    content: "\e907";
  }

  .icon-icon-p-busca:before {
    content: "\e909";
  }

  .icon-icon-p-aguardando:before {
    content: "\e90a";
  }

  .icon-icon-p-enviado:before {
    content: "\e90b";
    color: #308BCB;
  }

  .icon-icon-busca:before {
    content: "\e90c";
  }

  .icon-icon-mais:before {
    content: "\e90d";
  }

  .icon-icon-esquerda:before {
    content: "\e90e";
  }

  .icon-icon-voltar:before {
    content: "\e90f";
  }

  .icon-icon-espera:before {
    content: "\e910";
  }

  .icon-icon-avancar:before {
    content: "\e911";
  }

  .icon-icon-ajuda:before {
    content: "\e912";
  }

  .icon-icon-o-espera:before {
    content: "\e913";
    color: #F3822C;
  }

  .icon-icon-o-cancel:before {
    content: "\e914";
    color: #E92E09;
  }

  .fa-user-tie:before {
    content: "\e914";
  }

  .fa-file-archive-o:before {
    content: "\e914";
    color: #3f033a;
  }

  .icon-icon-down:before {
    content: "\e915";
  }

  .icon-icon-pedidos:before {
    content: "\e916";
  }

  .icon-icon-up:before {
    content: "\e917";
  }

  .icon-icon-dashborad:before {
    content: "\e918";
  }

  .icon-icon-diretita:before {
    content: "\e919";
  }

  .icon-icon-orcamentos:before {
    content: "\e91a";
  }

  .icon-icon-menos:before {
    content: "\e91b";
  }

  .icon-icon-clientes:before {
    content: "\e91c";
  }

  .icon-icon-h:before {
    content: "\e91d";
  }

  .icon-icon-add-cliente:before {
    content: "\e91e";
  }

  .icon-icon-ckeck:before {
    content: "\e91f";
  }

  .icon-icon-troca:before {
    content: "\e920";
  }

  .icon-icon-fecha:before {
    content: "\e921";
  }

  .icon-icon-atualiza-val:before {
    content: "\e922";
  }

  .icon-icon-imprime:before {
    content: "\e923";
  }

  .icon-icon-lista:before {
    content: "\e924";
  }

  .icon-icon-copia:before {
    content: "\e925";
  }

  .icon-icon-perfil:before {
    content: "\e926";
  }

  .icon-icon-edita:before {
    content: "\e927";
  }

  .icon-icon-esquadria:before {
    content: "\e928";
  }

  .icon-icon-baixa:before {
    content: "\e929";
  }

  .icon-icon-obra:before {
    content: "\e92a";
  }

  .icon-icon-sai:before {
    content: "\e92b";
  }

  .icon-icon-cor:before {
    content: "\e92c";
  }

  .icon-icon-abre:before {
    content: "\e92d";
  }

  .icon-icon-dinheiro:before {
    content: "\e92e";
  }

  .icon-icon-carrinho:before {
    content: "\e92f";
  }

  .icon-icon-ver-janela:before {
    content: "\e930";
  }

  .icon-icon-adc-carrinho:before {
    content: "\e931";
  }

  .icon-icon-ver:before {
    content: "\e932";
  }

  .icon-icon-carrinho-aberto:before {
    content: "\e933";
  }

  .icon-icon-estrela:before {
    content: "\e934";
  }

  .icon-icon-alerta:before {
    content: "\e935";
  }

  .icon-icon-chat-star:before {
    content: "\e936";
  }

  .icon-lixeira:before {
    content: "\e937";
    color: #66615b
  }

  .icon-icon-envia:before {
    content: "\e938";
  }

  .icon-icon-edita-salva:before {
    content: "\e939";
  }

  .icon-icon-o-ok:before {
    content: "\e93a";
    color: #22771C;
  }

  .icon-icon-edita-info:before {
    content: "\e93b";
  }

  .icon-icon-o-edita:before {
    content: "\e93c";
    color: #57607A;
  }

  .icon-icon-pdf:before {
    content: "\e93d";
  }

  .icon-icon-o-recebido:before {
    content: "\e93e";
    color: #7B34CC;
  }

  .icon-icon-info:before {
    content: "\e93f";
  }

  .icon-icon-o-busca:before {
    content: "\e940";
  }

  .icon-icon-inco-2:before {
    content: "\e941";
  }

  .icon-icon-o-up:before {
    content: "\e942";
  }

  .orcamentos1,
  .orcamentos2,
  .orcamentos3,
  .orcamentos4,
  .orcamentos5,
  .orcamentos6,
  .orcamentos7,
  .orcamentos8,
  .orcamentos9,
  .orcamentos10 {
    display: flex;
  }

  .orcamentos1 p,
  .orcamentos2 p,
  .orcamentos3 p,
  .orcamentos4 p,
  .orcamentos5 p,
  .orcamentos6 p,
  .orcamentos7 p,
  .orcamentos8 p,
  .orcamentos9 p,
  .orcamentos10 p {
    margin-top: 23px;
  }

  td {
    height: 60px;
  }

  .orcamentos1 span {
    background-color: rgba(87, 96, 122, 10%);
    padding: 10px;
    border-radius: 10px;
    font-size: 24px;
    margin: 0 10px 0 10px;
  }

  .orcamentos2 span {
    background-color: rgba(243, 130, 44, 10%);
    padding: 10px;
    border-radius: 10px;
    font-size: 24px;
    margin: 0 10px 0 10px;
  }

  .orcamentos3 span {
    background-color: rgba(233, 46, 9, 10%);
    padding: 10px;
    border-radius: 10px;
    font-size: 24px;
    margin: 0 10px 0 10px;
  }

  .orcamentos4 span {
    background-color: rgba(123, 52, 204, 10%);
    padding: 10px;
    border-radius: 10px;
    font-size: 24px;
    margin: 0 10px 0 10px;
  }

  .orcamentos5 span {
    background-color: rgba(48, 139, 203, 10%);
    padding: 10px;
    border-radius: 10px;
    font-size: 24px;
    margin: 0 10px 0 10px;
  }

  .orcamentos6 span {
    background-color: rgba(214, 54, 104, 10%);
    padding: 10px;
    border-radius: 10px;
    font-size: 24px;
    margin: 0 10px 0 10px;
  }

  .orcamentos7 span {
    background-color: rgba(78, 170, 99, 10%);
    padding: 10px;
    border-radius: 10px;
    font-size: 24px;
    margin: 0 10px 0 10px;
  }

  .orcamentos8 span {
    background-color: rgba(34, 119, 28, 10%);
    padding: 10px;
    border-radius: 10px;
    font-size: 24px;
    margin: 0 10px 0 10px;
  }

  .orcamentos9 span {
    background-color: rgba(3, 28, 253, 0.24);
    padding: 10px;
    border-radius: 10px;
    font-size: 24px;
    margin: 0 10px 0 10px;
  }

  .orcamentos10 span {
    background-color: rgba(168, 161, 21, 0.207);
    padding: 10px;
    border-radius: 10px;
    font-size: 24px;
    margin: 0 10px 0 10px;
  }

  .bottom1 {
      background-color: #4EAA79 !important;
      border-radius: 10px !important;
      box-shadow: 0px 5px 5px rgba(198, 203, 212,.5) !important;
  }

  .bottomNovo{
    background-color: #4EAA79 !important;
    border-radius: 10px !important;
    box-shadow: 0px 5px 5px rgba(198, 203, 212, .5) !important;
  }

  .bottomNovo {
    background-color: #4EAA79 !important;
    border-radius: 10px !important;
    box-shadow: 0px 5px 5px rgba(198, 203, 212, .5) !important;
  }

  .bottom1 p {
    text-transform: none !important;
    font-size: 16px !important;
    display: flex;
    margin-left: 25px;
    height: 44px !important;
    margin-bottom: -25px;
  }

  .bottom1 i {
    font-size: 24px !important;
    display: flex;
    margin-bottom: -20px;
    margin-left: -8px;
    margin-top: 0px;
  }

  .novoExcluir {
    background-color: #4EAA79 !important;
    border-radius: 10px !important;
    box-shadow: 0px 5px 5px rgba(198, 203, 212, .5) !important;
  }

  .novoExcluir p {
    text-transform: none !important;
    font-size: 16px !important;
    display: flex !important;
    margin-left: 25px;
    height: 44px !important;
    margin-bottom: -25px;
  }

  .novoExcluir i {
    font-size: 24px !important;
    display: flex !important;
    margin-bottom: -20px;
    margin-left: -8px;
    margin-top: 0px;
  }

  .novoParametrizacao {
    background-color: #4EAA79 !important;
    border-radius: 10px !important;
    box-shadow: 0px 5px 5px rgba(198, 203, 212, .5) !important;
  }

  .novoParametrizacao p {
    text-transform: none !important;
    font-size: 16px !important;
    display: flex !important;
    margin-left: 25px;
    height: 44px !important;
    margin-bottom: -25px;
  }

  .novoParametrizacao i {
    font-size: 24px !important;
    display: flex !important;
    margin-bottom: -20px;
    margin-left: -8px;
    margin-top: 0px;
  }

  .bottomNovo p {
    text-transform: none !important;
    font-size: 16px !important;
    display: flex !important;
    margin-left: 25px;
    height: 44px !important;
    margin-bottom: -25px;
  }

  .bottomNovo i {
    font-size: 24px !important;
    display: flex !important;
    margin-bottom: -20px;
    margin-left: -8px;
    margin-top: 0px;
  }

  .btnCliente {
    background-color: #4EAA79 !important;
    border-radius: 10px !important;
    box-shadow: 0px 5px 5px rgba(198, 203, 212, .5) !important;
  }

  .btnCliente p {
    text-transform: none !important;
    font-size: 16px !important;
    display: flex;
    margin-left: 25px;
    height: 44px !important;
    margin-bottom: -25px;
  }

  .btnCliente i {
    font-size: 24px !important;
    display: flex;
    margin-bottom: -20px;
    margin-left: -8px;
    margin-top: 0px;
  }

  .bottom2 {
    background-color: #3073CB !important;
    border-radius: 10px !important;
    box-shadow: 0px 5px 5px rgba(198, 203, 212, .5) !important;
  }

  .bottom2 p {
    text-transform: none !important;
    font-size: 16px !important;
    display: flex;
    margin-left: 21px;
    height: 44px !important;
    margin-bottom: -25px;
  }

  .bottom2 i {
    font-size: 24px !important;
    display: flex;
    margin-bottom: -20px;
    margin-left: -8px;
    margin-top: 0px;
  }

  .table-bordered {
    border: none !important;
  }


  .icon-icon-info {
    font-size: 10px !important;
    margin-top: 0px;
    position: absolute;
  }

  .check-orcamentos {
    position: absolute;
    height: 24px;
    width: 24px;
    top: -2px;
    border-radius: 10px !important;
    box-shadow: 0px 5px 5px rgba(198, 203, 212, .5) !important;
    font-family: 'Inter', sans-serif !important;
    color: #57607A !important;
    border: 0 none !important;
    outline: 0 !important;
  }




  .check-filtros {
    position: absolute;
    top: -5px;
    left: 50px;
    border-radius: 10px !important;
    box-shadow: 0px 5px 5px rgba(198, 203, 212,.5) !important;
      font-family: 'Inter', sans-serif !important;
      color: #57607A !important;
      border: 0 none !important;
      outline: 0 !important;
  }

  .check-pesquisa{
    position: absolute;
    top: -15px;
    left: 50px;
    height: 50px !important;
    width: 300px !important;
    border-radius: 10px !important;
    box-shadow: 0px 5px 5px rgb(198 203 212 / 50%) !important;
    font-family: 'Inter', sans-serif !important;
    color: #57607A !important;
    border: 0 none !important;
    outline: 0 !important;
  }

  .check-pesquisa {
    position: absolute;
    top: -15px;
    left: 50px;
    height: 50px !important;
    width: 300px !important;
    border-radius: 10px !important;
    box-shadow: 0px 5px 5px rgba(198, 203, 212, .5) !important;
    font-family: 'Inter', sans-serif !important;
    color: #57607A !important;
    border: 0 none !important;
    outline: 0 !important;
  }

  .icon-icon-esquerda {
    width: 25px;
    height: 30px;
    background-color: #6b2c5f;
    color: #fff;
    border-radius: 10px 0px 0px 10px;
    padding: 8px;
  }

  .icon-icon-diretita {
    width: 25px;
    height: 30px;
    background-color: #6b2c5f;
    color: #fff;
    border-radius: 0px 10px 10px 0px;
    padding: 8px;
  }

  .pagination .page-item.active > .page-link {
      width: 50px;
      height: 30px;
      border-radius: 10px !important;
      box-shadow: 0px 5px 5px rgba(198, 203, 212,.5) !important;
      color: #57607A !important;
      background-color: #fff !important;
  }

  .react-datepicker__input-container{
      display: inline-table;
  }

  .react-datepicker__close-icon {
      position: relative;
      margin: 0px 0px 0px -28px;
      top: 0;
      right: 0;
  }
